import { Table } from "../../components/Table/table";
import { useAccountUsers } from "../../context/AccountUsersContext";
import MasterLayout from "../../layout/MasterLayout";

const Users = () => {
  const { users } = useAccountUsers();
  return (
    <MasterLayout>
      <Table users={users} />
    </MasterLayout>
  );
};

export default Users;
