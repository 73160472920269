import { useEffect, useState } from "react";
// import { admin as adminApi } from "../../api";

const RolesComponent = ({ roleAccess, role, module, feature }: any) => {
  return (
    <div className="table-responsive iq-pricing pt-2" style={{ flexGrow: "1" }}>
      <table
        id="my-table"
        className="table rolestable"
        data-active="premium"
        style={{ width: "100%" }}
      >
        <RolesHeader roles={role} />
        <tbody>
          <RolesBody roles={role} feature={feature} roleAccess={roleAccess} />
        </tbody>
      </table>
    </div>
  );
  // return (
  //     <Container fluid={true}>
  //       <Stack
  //         direction="horizontal"
  //         className="align-items-center my-4 d-sm-blo"
  //       >
  //         <Breadcrumb className="flex-grow-1 mb-0">
  //           <Breadcrumb.Item active>
  //             <div className="fontweight600">Roles</div>
  //           </Breadcrumb.Item>
  //         </Breadcrumb>
  //       </Stack>
  //       <AdminRolesOutlet
  //         updateRole={updateRole}
  //         rolesMaster={rolesMaster}
  //         modulesMaster={modulesMaster}
  //         roles={roles}
  //       />
  //     </Container>
  // );
};

export default RolesComponent;

// const updateRole = async (
//   name: string,
//   module: string,
//   features: Array<string>
// ) => {
//   try {
//     // const res = await adminApi.updateRole(name, module, features);
//     // if (res.status === 200) {
//     //   await getRoles();
//     // }
//   } catch (e) {
//     console.error("Failed to update Role, ", e);
//   } finally {
//   }
// };

const RolesBody = ({ feature, roles, roleAccess }: any) => {
  const handleChange = (key: any, v: any, r: any, features: any) => {
    const roleData = roles.find(
      (role: any) =>
        role.module === key && role.features.includes(v) && role.name === r
    );

    if (roleData) {
      // If checked, remove feature
      if (roleData.features.includes(v)) {
        roleData.features = roleData.features.filter(
          (feature: string) => feature !== v
        );
      } else {
        // If not checked, add feature
        roleData.features.push(v);
      }
    } else {
      console.error(
        `Role not found for module: ${key}, feature: ${v}, role name: ${r}`
      );
    }
  };

  const [setAnalyst] = useState<any>(roleAccess["ANALYST"]);
  const [setGuest] = useState<any>(roleAccess["GUEST"]);
  const [setOwner] = useState<any>(roleAccess["OWNER"]);

  useEffect(() => {
    if (roleAccess) {
      setAnalyst(roleAccess["ANALYST"]);
      setGuest(roleAccess["GUEST"]);
      setOwner(roleAccess["OWNER"]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleAccess]);

  return (
    <>
      {/* {feature&&} */}
      {Object.keys(feature).map((_key) => {
        return (
          <tr style={{ borderBottom: "1px solid #e2e8f0" }}>
            <th className="text-center padtop150" scope="row">
              {" "}
              {_key}
              {/* // USER, ACCOUNT, SESSION */}
            </th>
            {/* {roles.map((r: any) => { // USER, ACCOUNT, SESSION */}
            {Object.keys(roleAccess).map((r: any) => {
              // USER, ACCOUNT, SESSION
              return (
                <td className="text-center iq-child-cell">
                  <ul className="rolelist">
                    {feature[_key].map((_v: any) => {
                      return (
                        <li
                          style={{
                            listStyle: "none",
                            textAlign: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <input
                            type="checkbox"
                            className="checkboxroles checkboc form-check-input "
                            onChange={(e) =>
                              handleChange(_key, _v, r, feature[_key])
                            }
                            // checked={

                            // }

                            // checked={
                            //   !!roles.find(
                            //     (role: any) =>
                            //       role.module === _key &&
                            //       role.features.includes(_v) &&
                            //       role.name === r
                            //   )
                            // }
                          ></input>{" "}
                          <p>{_v}</p>
                        </li>
                      );
                    })}
                  </ul>
                </td>
              );
            })}
          </tr>
        );
      })}
    </>
  );
};

const RolesHeader = ({ roles }: any) => {
  return (
    <thead>
      <tr>
        <HeaderComponent item={"Functionality"} />
        {roles.map((h: any) => {
          return <HeaderComponent item={h} />;
        })}
      </tr>
    </thead>
  );
};

const HeaderComponent = ({ item }: any) => {
  return (
    <th
      className="text-center iq-price-head"
      style={{ paddingTop: "20px", paddingBottom: "20px" }}
    >
      <span className="type">{item}</span>
    </th>
  );
};
