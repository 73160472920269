import { useToast } from "@chakra-ui/react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { IUser } from "../interfaces/IUser";
import userService from "../services/user.service";

const defaultState = {
  members: [],
  changeMemberFilter: (_meta: any) => {},
  getMembers: () => {},
  inviteAdmin: (parmas: any) => {},
  deleteMembers: (id: string) => {},
  updateAdmin: (id: string, params: any) => {},
  membersMeta: {
    pageLimit: 0,
    currentPage: 0,
    total_count: 0,
  },
};

const MembersContext = createContext(defaultState);

export const MembersProvider = ({ children }: any) => {
  const [members, setMembers] = useState<IUser[] | any>([]);
  const [membersMeta, setMembersMeta] = useState<any>([]);
  const toast = useToast();
  const changeMemberFilter = (_meta: any) => {
    let meta: any = {
      page: _meta.currentPage || _meta.page,
      limit: _meta.pageLimit || 10,
    };

    if (_meta.sort) {
      meta.sort = _meta.sort;
    }

    getMembers(meta);
  };

  const getMembers = async (_meta?: any) => {
    const res = await userService.getUsers(_meta);
    if (res?.success) {
      setMembers(res.admins);
      setMembersMeta(res.meta);
    }
  };

  const deleteMembers = async (id: string) => {
    const res = await userService.deleteAdmin(id);
    if (res?.success) {
      toast({
        title: `Admin Deleted`,
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
      return res;
    } else {
      toast({
        title: res ? res : "Deleted Failed",
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const updateAdmin = async (id: string, params: any) => {
    const res = await userService.updateAdmin(id, params);
    if (res?.success) {
      setMembers(res.admins);
    }
    return res;
  };

  const inviteAdmin = async (params: any) => {
    const res = await userService.inviteAdmin(params);
    if (res?.success) {
      toast({
        title: `${params?.first_name} ${params?.last_name} Invited`,
        status: "success",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
    }
    return res;
  };

  // const getMember = async (id: string) => {
  //   const res = await userService.getUsers()
  //   if (res?.success) {
  //       setMembers(res.admins)
  //   }
  // }

  useEffect(() => {
    getMembers();
  }, []);

  const value = useMemo(
    () => ({
      members,
      changeMemberFilter,
      membersMeta,
      getMembers,
      inviteAdmin,
      deleteMembers,
      updateAdmin,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [members]
  );
  return (
    <MembersContext.Provider value={value}>{children}</MembersContext.Provider>
  );
};

export const useMembers = () => {
  return useContext(MembersContext);
};
