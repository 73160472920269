import { useToast } from "@chakra-ui/react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import accountService from "../services/account.service";

const defaultState = {
  account: null,
  getAccountById: (id: string) => {},
  updateAccountById: (id: string, params: any) => {},
  regenerateApiKey: (id: string) => {},
  createAccount: (params: any) => {},
};

const AccountContext = createContext(defaultState);

export const AccountProvider = ({ children }: any) => {
  const [account, setAccount] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const toast = useToast();
  const { id } = useParams();
  const navigate = useNavigate();

  const createAccount = useCallback(
    async (params: any) => {
      const res = await accountService.createAccount(params);
      if (res?.success) {
        toast({
          title: "Account Created",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        navigate("/accounts");
      } else {
        toast({
          title: "Validation Error",
          status: "error",
          duration: 3000,
          isClosable: true,
          description: res,
          position: "top-right",
        });
      }
      return res;
    },
    [navigate, toast]
  );

  const getAccountById = async (id: string) => {
    const res = await accountService.getAccountById(id);
    if (res?.success) {
      setAccount(res.account);
    }
  };

  const updateAccountById = async (id: string, params: any) => {
    if (id) {
      const res = await accountService.updateAccountById(id!, params);

      if (res?.success) {
        setAccount(res.account);
        setSuccess(res?.success);
      }
      return res;
    }
  };

  const regenerateApiKey = async (id: string) => {
    const res = await accountService.regenerateApiKey(id);
    if (res?.success) {
      setAccount(res.account);
    }
  };

  useEffect(() => {
    if (id) {
      getAccountById(id!);
    }
  }, [id]);

  const value = useMemo(
    () => ({
      account,
      success,
      getAccountById,
      updateAccountById,
      regenerateApiKey,
      createAccount,
    }),
    [account, createAccount, success]
  );
  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  );
};

export const useAccount = () => {
  return useContext(AccountContext);
};
