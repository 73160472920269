// services
import { IAccount } from "../interfaces/IAccount";
import APIService from "./api.service";

const { REACT_APP_USER_BASE_URL } = process.env;

const filterQueryParams = (params: any = {}) => {
  Object.keys(params).forEach((key) => {
    if (params[key] === null || params[key]?.length < 1 || params[key] === "") {
      delete params[key];
    }
  });
  let rating: Array<number> = [];
  if (params?.rating?.length) {
    rating = [...params.rating];
    delete params.rating;
  }
  const searchParams = new URLSearchParams(params);
  rating.map((rate) => searchParams.append("rating", rate.toString()));
  return searchParams.toString();
};

class AccountService extends APIService {
  constructor() {
    super(REACT_APP_USER_BASE_URL || "http://localhost:8000");
  }

  async getAccountById(id: string): Promise<any> {
    return this.get(`admin/accounts/${id}`, { headers: {} })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
        // throw error?.response?.data?.message;
      });
  }

  async updateAccountById(id: string, params: any): Promise<any> {
    return this.put(`admin/accounts/${id}`, params, {})
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
        // throw error?.response?.data?.message;
        return error?.response?.data;
      });
  }

  async regenerateApiKey(id: string): Promise<any> {
    return this.post(`admin/accounts/${id}/regenerate`, { headers: {} })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
        // throw error?.response?.data?.message;
      });
  }

  async getAllUsers(params?: any): Promise<any> {
    return this.get(`admin/accounts/users?${filterQueryParams(params)}`, {})
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
        // throw error?.response?.data?.message;
      });
  }

  async getAccountUsers(id: string, meta?: any): Promise<any> {
    return this.get(`admin/accounts/${id}/users?${filterQueryParams(meta)}`, {
      headers: {},
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
        // throw error?.response?.data?.message;
        return error?.response?.data?.message;
      });
  }

  async getUserByAccountId(accountId: string, userId: string): Promise<any> {
    return this.get(`admin/accounts/${accountId}/users/${userId}`, {
      headers: {},
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
        // throw error?.response?.data?.message;
      });
  }

  async updateUserByAccountId(
    accountId: string,
    userId: string,
    params: IAccount
  ): Promise<any> {
    return this.put(`admin/accounts/${accountId}/users/${userId}`, params, {})
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
        // throw error?.response?.data?.message;
        return error?.response?.data?.message;
      });
  }

  async deleteUserByAccountId(accountId: string, userId: string): Promise<any> {
    return this.delete(`admin/accounts/${accountId}/users/${userId}`, {})
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
        // throw error?.response?.data?.message;
        return error?.response?.data?.message;
      });
  }

  async getAccounts(meta?: any): Promise<any> {
    return this.get(
      `admin/accounts?${meta || ""}`,
      {}
    )
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
        // throw error?.response?.data?.message;
      });
  }

  async createAccount(params: IAccount): Promise<any> {
    return this.post("admin/accounts", params, {})
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
        return error?.response?.data?.message;
        // throw error?.response?.data?.message;
      });
  }

  async inviteUser(id: string, params: any): Promise<any> {
    return this.post(`admin/accounts/${id}/users/invite`, params, {})
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
        return error?.response?.data?.message;
        // throw error?.response?.data?.message;
      });
  }

  async blockAccount(
    id: string,
    params: { blocked_until: string }
  ): Promise<any> {
    return this.post(`admin/accounts/${id}/block`, params, {})
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
        return error?.response?.data?.message;
        // throw error?.response?.data?.message;
      });
  }

  async unblockAccount(id: string): Promise<any> {
    return this.post(`admin/accounts/${id}/unblock`, {}, {})
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
        return error?.response?.data?.message;
        // throw error?.response?.data?.message;
      });
  }

  async blockUser(
    accountId: string,
    userId: string,
    params: { blocked_until: string }
  ): Promise<any> {
    return this.post(
      `admin/accounts/${accountId}/users/${userId}/block`,
      params,
      { headers: {} }
    )
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
        return error?.response?.data?.message;
        // throw error?.response?.data?.message;
      });
  }

  async unblockUser(
    accountId: string,
    userId: string,
    params: { blocked_until: string }
  ): Promise<any> {
    return this.post(
      `admin/accounts/${accountId}/users/${userId}/unblock`,
      params,
      { headers: {} }
    )
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
        return error?.response?.data?.message;
        // throw error?.response?.data?.message;
      });
  }

  async deleteAccount(accountId: string) {
    return this.delete(`admin/accounts/${accountId}`, {})
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
        return error?.response?.data?.message;
      });
  }

  async getSessionByAccount(accountId:string,meta?: any){
    return this.get(`admin/sessions/accounts/${accountId}?${filterQueryParams(meta?meta:{sort:"-createdAt"})}`,{headers:{}})
    .then((response)=>{
      return response?.data
    })
    .catch((error)=>{
      return error?.response?.data?.message
    })
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AccountService();
