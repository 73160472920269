import { Navigate, Route, Routes } from "react-router-dom";
import { AccountProvider } from "../context/AccountContext";
import { AccountsProvider } from "../context/AccountsContext";
import { AccountUsersProvider } from "../context/AccountUsersContext";
import { AllUsersProvider } from "../context/AllUsersContext";
import { useAuth } from "../context/AuthContext";
import { MembersProvider } from "../context/MembersContext";
import { RolesProvider } from "../context/RolesContext";
import { SessionsProvider } from "../context/SessionsContext";
import { Account } from "../pages/Home/Account";
import { AccountConfig } from "../pages/Home/AccountConfig";
import Accounts from "../pages/Home/Accounts";
import AllUsers from "../pages/Home/AllUsers";
import { ChangePassword } from "../pages/Home/ChangePassword";
import { CreateAccount } from "../pages/Home/CreateAccount";
import Dashboard from "../pages/Home/Dashboard";
import Members from "../pages/Home/Members";
import { Profile } from "../pages/Home/Profile";
import Roles from "../pages/Home/Roles";
import Sessions from "../pages/Home/Sessions";
import Users from "../pages/Home/Users";

export const ProtectedRoute = ({ children }: any) => {
  const { user } = useAuth();
  if (!user) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  return children;
};

export const PrivateRoutes = () => (
  // <AuthProvider>
  <Routes>
    <Route path="/*" element={<Navigate to="/dashboard" />} />
    <Route path="/dashboard" element={<Dashboard />} />
    <Route
      path="/members"
      element={
        <MembersProvider>
          <Members />
        </MembersProvider>
      }
    />
    {/* <Route path="/members/:id" element={<Member />} /> */}
    <Route path="/profile" element={<Profile />} />
    <Route path="/change_password" element={<ChangePassword />} />
    <Route
      path="/accounts"
      element={
        <AccountsProvider>
          <Accounts />
        </AccountsProvider>
      }
    />
    <Route
      path="/accounts/new"
      element={
        <AccountProvider>
          <CreateAccount />
        </AccountProvider>
      }
    />
    <Route
      path="/accounts/:id"
      element={
        <AccountProvider>
          <Account />
        </AccountProvider>
      }
    />
    <Route
      path="/accounts/:id/config"
      element={
        <AccountProvider>
          <AccountConfig />
        </AccountProvider>
      }
    />
    <Route
      path="/accounts/:id/users"
      element={
        <AccountUsersProvider>
          <Users />
        </AccountUsersProvider>
      }
    />
    <Route
      path="/accounts/:id/sessions"
      element={
        <SessionsProvider>
          <Sessions />
        </SessionsProvider>
      }
    />
    <Route path="/sessions" element={<Sessions />} />
    <Route
      path="/users"
      element={
        <AllUsersProvider>
          <AllUsers />
        </AllUsersProvider>
      }
    />
    <Route
      path="/roles"
      element={
        <RolesProvider>
          <Roles />
        </RolesProvider>
      }
    />
  </Routes>
);
