import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { AuthRoutes } from "./authRoutes";
import { PrivateRoutes } from "./privateRoutes";

export const ProtectedRoute = ({ children }: any) => {
  const { user } = useAuth();
  if (!user) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  return children;
};

const RoutesComponent = () => {
  // const token = localStorage.getItem('accessToken')
  const { user } = useAuth();

  return (
    <React.Fragment>
      {!user ? <AuthRoutes /> : <PrivateRoutes />}
    </React.Fragment>

    // <Route path="/dashboard" element={
    //     <ProtectedRoute>
    //         <Dashboard />
    //     </ProtectedRoute>
    // } />
  );
};

export default RoutesComponent;
