import { Stat } from "../../components/Stat"
import MasterLayout from "../../layout/MasterLayout"
import { Box, Container, SimpleGrid } from '@chakra-ui/react'

const Dashboard = () => {

    return (
        <MasterLayout>
            <Stats />
        </ MasterLayout>
    )
}

export default Dashboard

const Stats = () => {

    const stats = [{
        label: 'Total Sessions',
        value: 4
    },{
        label: 'Total Sessions',
        value: 6
    }, {
        label: 'T Sessions',
        value: 123
    }]

    return (
        <Box width={'full'} as="section" py={{ base: '4', md: '8' }}>
            <Container>
                Sessions Stats
            <SimpleGrid columns={{ base: 1, md: 3 }} gap={{ base: '5', md: '6' }}>
                {stats.map(({ label, value }: any) => (
                    <Stat key={label} label={label} value={value} />
                ))}
            </SimpleGrid>
            </Container>
        </Box>
    )
}