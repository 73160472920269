import axios from "axios";
// import Cookies from 'js-cookie';

abstract class APIService {
  protected baseURL: string;
  protected headers: any = {};

  constructor(baseURL: string) {
    this.baseURL = baseURL;
  }

  //   setRefreshToken(token: string) {
  //     Cookies.set('refreshToken', token, { expires: 60 });
  //   }

  //   getRefreshToken() {
  //     return Cookies.get('refreshToken');
  //   }

  //   purgeRefreshToken() {
  //     Cookies.remove('refreshToken', { path: '/' });
  //   }

  setAccessToken(token: string) {
    localStorage.setItem("accessToken", token);
  }

  setResetToken(token: string) {
    localStorage.setItem("resetToken", token);
  }

  getAccessToken() {
    return localStorage.getItem("accessToken");
  }

  purgeAccessToken() {
    localStorage.remove("accessToken", { path: "/" });
  }

  getHeaders() {
    return {
      ...(this.getAccessToken()
        ? { Authorization: `Bearer ${this.getAccessToken()}` }
        : {}),
    };
  }

  get(url: string, config = {}): Promise<any> {
    return axios({
      method: "get",
      url: this.baseURL + url,
      ...config,
      headers: this.getHeaders(),
    });
  }

  post(url: string, data = {}, config = {}): Promise<any> {
    return axios({
      method: "post",
      url: this.baseURL + url,
      data,
      headers: this.getHeaders(),
      ...config,
    });
  }

  put(url: string, data = {}, config = {}): Promise<any> {
    return axios({
      method: "put",
      url: this.baseURL + url,
      data,
      headers: this.getHeaders(),
      ...config,
    });
  }

  delete(url: string, config = {}): Promise<any> {
    return axios({
      method: "delete",
      url: this.baseURL + url,
      headers: this.getHeaders(),
      ...config,
    });
  }

  request(config = {}) {
    return axios(config);
  }
}

export default APIService;
