import {
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PasswordField } from "../../components";
import { useAuth } from "../../context/AuthContext";

export const Login = () => {
  const context = useAuth();
  const [email, setEmail] = useState<string>("");
  const toast = useToast();

  const [emailError, setEmailError] = useState<string | null>("");
  const [passwordError, setPasswordError] = useState<string | null>(null);

  const navigate = useNavigate();
  const passwordRef: any = useRef<any>(null);
  const toastId = "password-toast";

  const validateEmail = (email: string) => {
    if (!email) {
      return "Email address is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      return "Invalid email address";
    } else {
      return null;
    }
  };

  const validatePassword = (password: string) => {
    if (!password) {
      return "Password is required";
    } else if (password.length < 8) {
      return "Password is too short - should be 8 characters minimum";
    } else if (!/[A-Z]/.test(password)) {
      return "Password must contain at least one capital letter";
    } else if (!/[a-z]/.test(password)) {
      return "Password must contain at least one lowercase letter";
    } else if (!/[0-9]/.test(password)) {
      return "Password must contain at least one number";
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      return "Password must contain at least one special character";
    } else {
      return null;
    }
  };
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    setEmailError(validateEmail(value));
  };

  const handlePasswordChange = () => {
    const password = passwordRef.current?.value || "";
    setPasswordError(validatePassword(password));
  };

  const handleSubmit = async () => {
    const emailValidationError = validateEmail(email);
    const passwordValidationError = validatePassword(
      passwordRef.current?.value || ""
    );

    setEmailError(emailValidationError);
    setPasswordError(passwordValidationError);

    if (!emailError && !passwordError) {
      const res: any = await context.login({
        email,
        password: passwordRef?.current?.value || "",
      });
      if (toast.isActive(toastId)) {
        toast.close(toastId);
      }

      if (res?.success) {
        toast({
          id: toastId,
          title: "Login successfull",
          status: "success",
          position: "top-right",
          isClosable: true,
        });
      } else {
        toast({
          id: toastId,
          title: res?.message,
          status: "error",
          position: "top-right",
          isClosable: true,
        });
      }
    }
  };

  return (
    <Container
      maxW="lg"
      py={{ base: "12", md: "24" }}
      px={{ base: "0", sm: "8" }}
    >
      <Stack spacing="8">
        <Stack spacing="4">
          <Center>
            <img
              alt="Logo"
              src="Skygram Logo png.png"
              className="mh-100px"
              width="300"
            />
          </Center>
          <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
            <Heading size={{ base: "xs", md: "sm" }}>
              Login to your account
            </Heading>
            <HStack spacing="1" justify="center">
              {/* <Text color="fg.muted">Don't have an account?</Text>
              <Button variant="text" size="lg" onClick={() => navigate('/signup')}>
                Sign up
              </Button> */}
            </HStack>
          </Stack>
        </Stack>
        <Box
          py={{ base: "0", sm: "8" }}
          px={{ base: "4", sm: "10" }}
          bg={{ base: "transparent", sm: "bg.surface" }}
          boxShadow={{ base: "none", sm: "md" }}
          borderRadius={{ base: "none", sm: "xl" }}
        >
          {/* <form onSubmit={handleSubmit}> */}
          <Stack spacing="6">
            <Stack spacing="5">
              <FormControl isInvalid={!!emailError}>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input
                  id="email"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                />
                {emailError && (
                  <FormErrorMessage>{emailError}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={!!passwordError}>
                <PasswordField
                  ref={passwordRef}
                  onChange={handlePasswordChange}
                />
                {passwordError && (
                  <FormErrorMessage>{passwordError}</FormErrorMessage>
                )}
              </FormControl>
            </Stack>
            <HStack justify="space-between">
              <Checkbox defaultChecked>Remember me</Checkbox>
              <Button
                onClick={() => navigate("/forget_password")}
                variant="text"
                size="sm"
              >
                Forgot password?
              </Button>
            </HStack>
            <Stack spacing="6">
              <Button
                onClick={handleSubmit}
                // type='submit'
                disabled={!email || !passwordRef.current?.value}
                variant="primary"
              >
                Sign in
              </Button>
            </Stack>
          </Stack>
          {/* </form> */}
        </Box>
      </Stack>
    </Container>
  );
};
