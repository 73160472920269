import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Badge,
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import { IoArrowDown } from "react-icons/io5";
import { TbLock, TbLockOpen } from "react-icons/tb";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAccounts } from "../../context/AccountsContext";
import accountService from "../../services/account.service";
import { accountFilterObject } from "../../utils";

interface ITable extends TableProps {
  accounts: any;
}

const AccountsTable = (props: ITable) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose }: any = useDisclosure();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  }: any = useDisclosure();
  const [deleteAccountId, setDeleteAccountId] = useState<string>("");
  const [blockId] = React.useState("");
  const { getAccounts } = useAccounts();
  const toast = useToast();
  const toastId = "password-toast";
  const [searchParams] = useSearchParams();

  const handleBlock = async (id: string) => {
    if (toast.isActive(toastId)) {
      toast.close(toastId);
    }

    const res = await accountService.blockAccount(id, {
      blocked_until: new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
      ).toISOString(),
    });

    if (res) {
      toast({
        id: toastId,
        title: "Account Blocked",
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
      getAccounts(accountFilterObject(searchParams));
    }
  };

  const handleUnblock = async (id: string) => {
    if (toast.isActive(toastId)) {
      toast.close(toastId);
    }

    const res = await accountService.unblockAccount(id);
    // unBlockAccount(id)
    if (res) {
      toast({
        id: toastId,
        title: "Account UnBlocked",
        status: "success",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
      getAccounts(accountFilterObject(searchParams));
    }
  };

  return (
    <React.Fragment>
      <Table {...props}>
        <Thead>
          <Tr>
            <Th>
              <HStack spacing="3">
                {/* <Checkbox /> */}
                <HStack spacing="1">
                  <Text>Name</Text>
                  <Icon as={IoArrowDown} color="fg.muted" boxSize="4" />
                </HStack>
              </HStack>
            </Th>
            <Th>Company Name</Th>
            <Th>Account Plan</Th>
            <Th>Location</Th>
            <Th>Agents Count</Th>
            <Th>Status</Th>
            <Th>Last Login</Th>
            <Th>Created date</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {props?.accounts.map((account: any) => (
            <Tr key={account.id}>
              <Td>
                <HStack spacing="3">
                  {/* <Checkbox /> */}
                  <Avatar
                    name={account.name}
                    src={account.avatarUrl}
                    boxSize="10"
                  />
                  <Box>
                    <Text fontWeight="medium">{account.name}</Text>
                    {/* <Text color="fg.muted">{(account.members.find((m: any) => m.role === 'OWNER'))?.email}</Text> */}
                    <Text color="fg.muted">{account.email}</Text>
                  </Box>
                </HStack>
              </Td>
              {/* <Td>{account?.meta?.businessName}</Td> */}
              <Td>
                <Text>
                  {account?.meta?.company_name
                    ? account?.meta?.company_name
                    : account.members.find((m: any) => m.role === "OWNER")?.meta
                        ?.company_name}
                </Text>
              </Td>
              <Td>
                {account?.meta?.accountPlan
                  ? account.meta.accountPlan.toUpperCase()
                  : account.members
                      .find((m: any) => m.role === "OWNER")
                      ?.meta?.accountPlan.toUpperCase()}
              </Td>
              <Td>
                <Text color="fg.muted">
                  {account?.meta?.location
                    ? account.meta.location
                    : account.members.find((m: any) => m.role === "OWNER")?.meta
                        ?.location}
                </Text>
              </Td>
              <Td>
                <Text color="fg.muted">
                  {/* {account?.meta?.teamSize} */}
                  {/* ( */}
                  {account.members.length}
                  {/* ) */}
                </Text>
              </Td>
              {/* <Td>
                <Text color="fg.muted">
                    <Rating defaultValue={account.rating} size="xl" />
                </Text>
                </Td> */}
              <Td>
                <Badge
                  size="sm"
                  colorScheme={
                    account.status === "ACTIVE" && account.is_active
                      ? "green"
                      : "red"
                  }
                >
                  {account.status === "ACTIVE" && !account.is_active
                    ? "PENDING"
                    : account?.status}
                </Badge>
              </Td>
              <Td>
                {account?.last_active_at
                  ? new Date(account?.last_active_at).toLocaleString("en-GB", {
                      weekday: "long",
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    })
                  : ""}
              </Td>
              <Td>
                {new Date(account.createdAt).toLocaleString("en-GB", {
                  weekday: "long",
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })}
              </Td>
              <Td>
                <HStack spacing="1">
                  {account.status === "ACTIVE" && !account.is_active ? (
                    <Tooltip
                      label={"Pending..."}
                      placement="top"
                      bg={"red.500"}
                    >
                      <IconButton
                        icon={<TbLockOpen fontSize="1.25rem" opacity={0.5} />}
                        variant="tertiary"
                        aria-label="Delete member"
                        isDisabled
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      label={account.status !== "BLOCKED" ? "Block" : "UnBlock"}
                      placement="top-start"
                      bg={
                        account.status === "BLOCKED"
                          ? "rgb(49, 151, 149)"
                          : "red.500"
                      }
                    >
                      <IconButton
                        icon={
                          account.status !== "BLOCKED" ? (
                            <TbLockOpen fontSize="1.25rem" />
                          ) : (
                            <TbLock fontSize="1.25rem" color="tomato" />
                          )
                        }
                        variant="tertiary"
                        aria-label="Delete member"
                        onClick={() => {
                          if (account.status === "BLOCKED") {
                            handleUnblock(account.id);
                          } else {
                            handleBlock(account.id);
                          }
                        }}
                      />
                    </Tooltip>
                  )}

                  {account.status === "ACTIVE" ? (
                    <IconButton
                      icon={<FiEdit2 fontSize="1.25rem" />}
                      variant="tertiary"
                      aria-label="Edit member"
                      onClick={() => {
                        navigate(`/accounts/${account.id}`);
                      }}
                    />
                  ) : (
                    <IconButton
                      icon={<FiEdit2 fontSize="1.25rem" />}
                      variant="tertiary"
                      aria-label="Edit member"
                      opacity={"0.3"}
                    />
                  )}

                  {account.status === "ACTIVE" && account.is_active ? (
                    <IconButton
                      icon={<FiTrash2 fontSize="1.25rem" />}
                      variant="tertiary"
                      aria-label="Edit member"
                      opacity={"0.3"}
                    />
                  ) : (
                    <IconButton
                      icon={<FiTrash2 fontSize="1.25rem" />}
                      variant="tertiary"
                      aria-label="Edit member"
                      onClick={() => {
                        setDeleteAccountId(account.id);
                        onOpenDelete();
                      }}
                    />
                  )}
                </HStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <DeleteAlert
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        handleBlock={handleBlock}
        id={blockId}
      />
      <DeleteAccount
        isOpen={isOpenDelete}
        onClose={onCloseDelete}
        userId={deleteAccountId}
      />
    </React.Fragment>
  );
};

export { AccountsTable };

const DeleteAlert = ({ isOpen, onOpen, onClose, handleBlock, id }: any) => {
  const cancelRef: any = React.useRef();
  const [value, setValue] = React.useState("");

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Block Customer
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You can't undo this action afterwards.
          </AlertDialogBody>

          <FormControl>
            <FormLabel htmlFor="blocked-until">Blocked until</FormLabel>
            <Input
              id="blocked-until"
              type="text"
              onChange={(e: any) => setValue(e.target.value)}
            />
          </FormControl>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={() => handleBlock(id, value)}
              ml={3}
            >
              Block
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

const DeleteAccount = ({ isOpen, onClose, userId }: any) => {
  const cancelRef: any = React.useRef();
  const { deleteAccount, getAccounts } = useAccounts();
  const toast = useToast();
  const toastId = "password-toast";
  const [searchParams] = useSearchParams();

  const handleDelete = async (id: string) => {
    if (toast.isActive(toastId)) {
      toast.close(toastId);
    }
    const res: any = await deleteAccount(id);
    if (res.success) {
      toast({
        id: toastId,
        title: "Account deleted successfully",
        status: "success",
        position: "top-right",
        isClosable: true,
      });
      getAccounts(accountFilterObject(searchParams));
    } else {
      toast({
        id: toastId,
        title: res,
        status: "error",
        position: "top-right",
        isClosable: true,
      });
    }
    onClose();
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete Account And Associated Users
          </AlertDialogHeader>
          <AlertDialogBody>
            Are you sure? You can't undo this action afterwards.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={() => handleDelete(userId)}
              ml={3}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
