import { createContext, useContext, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { IUser } from "../interfaces/IUser";
import authService from "../services/auth.service";
import userService from "../services/user.service";
import { useToast } from "@chakra-ui/react";

const defaultState = {
  user: null,
  getUser: () => {},
  login: (data: any) => {},
  logout: () => {},
  acceptInvitation: (token: string, data: any) => {},
  forgetPassword: ({ email }: { email: string }) => {},
  changePassword: (data: any) => {},
  resetPassword: (data: any) => {},
  uploadAvatar: (data: any) => {},
  updateUser: (id: string, data: any) => {},
  resendInvite: (id: string) => {},
  blockAdmin: (id: string, data: any) => {},
  unBlockAdmin: (id: string) => {},
};

const AuthContext = createContext(defaultState);

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();

  const toast = useToast();
  const toastId = "password-toast";

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUser = async () => {
    const res = await userService.getUser();

    if (res) {
      const user: IUser | null = res;
      if (!!user) {
        setUser(user);
      }
      return res;
    }
    return null;
  };

  // call this function when you want to authenticate the user
  const login = async (data: any) => {
    // try {
    const res = await authService.emailSignIn(data);
    if (res?.success) {
      const user: IUser | null = await getUser();
      if (!!user) {
        setUser(user);
      }

      navigate("/dashboard");
    }
    return res;
  };

  const acceptInvitation = async (token: string, data: any) => {
    const res = await authService.acceptInvitation(token, data);
    if (res?.success) {
      // navigate('/')
    } else {
      // handle invalid token error
    }
    return res;
  };

  const forgetPassword = async (data: any) => {
    const res = await authService.forgotPassword(data);
    return res;
  };

  const resetPassword = async (data: any) => {
    const res = await authService.resetPassword(data);
    return res;
  };

  const changePassword = async (data: any) => {
    const res = await userService.changePassword(data);
    return res;
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    localStorage.removeItem("accessToken");
    navigate("/", { replace: true });
  };

  const uploadAvatar = async (data: any) => {
    const res = await userService.uploadAvatar(data);
    if (res?.success) {
      const user: IUser | null = await getUser();
      if (!!user) {
        setUser(user);
      }
      return { success: true, url: user?.avatar };
    }

    return res;
  };

  const updateUser = async (id: string, data: any) => {
    const res = await userService.updateAdmin(id, data);
    if (res?.success) {
      await getUser();
    }
    return res;
  };

  const resendInvite = async (id: string) => {
    if (toast.isActive(toastId)) {
      toast.close(toastId);
    }
    const res = await userService.resendInvitation(id);
    if (res?.success) {
      toast({
        id: toastId,
        title: "Resent Invitation",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } else {
      toast({
        id: toastId,
        title: "Error Resent",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
    // return res
  };

  const blockAdmin = async (id: string, data: any) => {
    if (toast.isActive(toastId)) {
      toast.close(toastId);
    }
    const res = await userService.blockAdmin(id, data);
    if (res?.success) {
      toast({
        id: toastId,
        title: "Blocked Admin",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } else {
      toast({
        id: toastId,
        title: res ? res : "Error Blocking",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
    return res;
  };

  const unBlockAdmin = async (id: string) => {
    if (toast.isActive(toastId)) {
      toast.close(toastId);
    }
    const res = await userService.unBlockAdmin(id);
    if (res?.success) {
      toast({
        id: toastId,
        title: "UnBlocked Admin",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } else {
      toast({
        id: toastId,
        title: "Error UnBlocking",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
    return res;
  };

  const value = useMemo(
    () => ({
      user,
      getUser,
      login,
      logout,
      acceptInvitation,
      forgetPassword,
      changePassword,
      resetPassword,
      uploadAvatar,
      updateUser,
      resendInvite,
      blockAdmin,
      unBlockAdmin,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
