import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Stack,
  StackDivider,
  Switch,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount } from "../../context/AccountContext";

export const AccountComponent = () => {
  const { regenerateApiKey, account, updateAccountById }: any = useAccount();
  const [accountForm, setAccountForm] = useState<any>();
  const toast = useToast();
  const toastId = "password-toast";

  useEffect(() => {
    if (account) {
      setAccountForm({
        name: account?.name,
        companyUrl: account?.meta?.companyUrl,
        accountPlan: account?.meta?.accountPlan,
        company_name: account?.meta?.company_name,
        businessDescription: account?.meta?.businessDescription,
        corporationType: account?.meta?.corporationType,
        location: account?.meta?.location,
        teamSize: account?.meta?.teamSize,
        recording: account?.recording,
        mask_element: account?.mask_element,
      });
    }
  }, [account]);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    const params: any = {
      name: accountForm.name,
      recording: accountForm.recording,
      mask_element: accountForm.mask_element,
    };
    if (toast.isActive(toastId)) {
      toast.close(toastId);
    }

    delete accountForm.name;
    delete accountForm.recording;
    delete accountForm.mask_element;
    params["meta"] = accountForm;
    const res = await updateAccountById(
      account._id ? account._id : account.id,
      params
    );

    if (res?.success) {
      toast({
        id: toastId,
        title: "updated successfully",
        status: "success",
        position: "top-right",
        isClosable: true,
      });
    }
  };

  return (
    <Container maxW="container.xl" py={{ base: "4", md: "8" }}>
      {!!account && (
        <Stack spacing="5">
          <Stack
            spacing="4"
            direction={{ base: "column", sm: "row" }}
            justify="space-between"
          >
            <Flex>
              <Text textStyle="lg" fontWeight="medium">
                Your Account
              </Text>
              <Button
                onClick={() =>
                  regenerateApiKey(account._id ? account._id : account.id)
                }
                mx={{ base: "2", md: "4" }}
                size="sm"
                variant="primary"
                colorScheme="green"
                alignSelf="start"
              >
                Regenerate Api Key
              </Button>
            </Flex>
            <Flex>
              <Button
                onClick={() =>
                  navigate(
                    `/accounts/${account._id ? account._id : account.id}/config`
                  )
                }
                variant="primary"
                colorScheme="yellow"
                fontWeight="medium"
              >
                Config
              </Button>
              <Button
                onClick={() =>
                  navigate(
                    `/accounts/${account._id ? account._id : account.id}/users`
                  )
                }
                mx={{ base: "2", md: "4" }}
                variant="primary"
                fontWeight="medium"
              >
                Show Users
              </Button>
              <Button
                onClick={() =>
                  navigate(
                    `/accounts/${
                      account._id ? account._id : account.id
                    }/sessions`
                  )
                }
                mx={{ base: "2", md: "4" }}
                variant="primary"
                colorScheme="green"
              >
                Sessions
              </Button>
              <Button
                onClick={() => handleSubmit()}
                variant="primary"
                alignSelf="start"
              >
                Save
              </Button>
            </Flex>
          </Stack>
          <Divider />
          <Stack spacing="5" divider={<StackDivider />}>
            <FormControl id="name">
              <Stack
                direction={{ base: "column", md: "row" }}
                spacing={{ base: "1.5", md: "8" }}
                justify="space-between"
              >
                <FormLabel variant="inline">Account Name</FormLabel>
                <Input
                  onChange={(e: any) =>
                    setAccountForm({ ...accountForm, name: e.target.value })
                  }
                  maxW={{ md: "3xl" }}
                  value={accountForm?.name}
                />
              </Stack>
            </FormControl>
            <FormControl id="name">
              <Stack
                direction={{ base: "column", md: "row" }}
                spacing={{ base: "1.5", md: "8" }}
                justify="space-between"
              >
                <FormLabel variant="inline">Account Tax Id</FormLabel>
                <Input
                  onChange={(e: any) =>
                    setAccountForm({ ...accountForm, name: e.target.value })
                  }
                  maxW={{ md: "3xl" }}
                  value={accountForm?.name}
                />
              </Stack>
            </FormControl>
            <FormControl id="name">
              <Stack
                direction={{ base: "column", md: "row" }}
                spacing={{ base: "1.5", md: "8" }}
                justify="space-between"
              >
                <FormLabel variant="inline">Api Key</FormLabel>
                <Input
                  disabled={true}
                  maxW={{ md: "3xl" }}
                  value={account?.api_key}
                />
              </Stack>
            </FormControl>
            <FormControl id="website">
              <Stack
                direction={{ base: "column", md: "row" }}
                spacing={{ base: "1.5", md: "8" }}
                justify="space-between"
              >
                <FormLabel variant="inline">Company Url</FormLabel>
                <InputGroup maxW={{ md: "3xl" }}>
                  <InputLeftAddon>https://</InputLeftAddon>
                  <Input
                    onChange={(e: any) =>
                      setAccountForm({
                        ...accountForm,
                        companyUrl: e.target.value,
                      })
                    }
                    value={accountForm?.companyUrl}
                  />
                </InputGroup>
              </Stack>
            </FormControl>
            <FormControl id="name">
              <Stack
                direction={{ base: "column", md: "row" }}
                spacing={{ base: "1.5", md: "8" }}
                justify="space-between"
              >
                <FormLabel variant="inline">Account Plan</FormLabel>
                <Select
                  onChange={(e: any) =>
                    setAccountForm({
                      ...accountForm,
                      accountPlan: e.target.value,
                    })
                  }
                  value={accountForm?.accountPlan}
                  maxW={{ md: "3xl" }}
                  placeholder="Select Account Plan"
                >
                  <option value="FREE">Free</option>
                  <option value="PRO">Pro</option>
                  <option value="ENTERPRISE">Enterprise</option>
                </Select>
                {/* <Input onChange={(e: any) => setAccountForm({...accountForm, accountPlan: e.target.value})} maxW={{ md: '3xl' }} value={accountForm?.accountPlan} /> */}
              </Stack>
            </FormControl>
            <FormControl id="name">
              <Stack
                direction={{ base: "column", md: "row" }}
                spacing={{ base: "1.5", md: "8" }}
                justify="space-between"
              >
                <FormLabel variant="inline">Company Name</FormLabel>
                <Input
                  onChange={(e: any) =>
                    setAccountForm({
                      ...accountForm,
                      company_name: e.target.value,
                    })
                  }
                  maxW={{ md: "3xl" }}
                  value={accountForm?.company_name}
                />
              </Stack>
            </FormControl>
            <FormControl id="name">
              <Stack
                direction={{ base: "column", md: "row" }}
                spacing={{ base: "1.5", md: "8" }}
                justify="space-between"
              >
                <FormLabel variant="inline">Corporation Type</FormLabel>
                <Input
                  onChange={(e: any) =>
                    setAccountForm({
                      ...accountForm,
                      corporationType: e.target.value,
                    })
                  }
                  maxW={{ md: "3xl" }}
                  value={accountForm?.corporationType}
                />
              </Stack>
            </FormControl>
            <FormControl id="name">
              <Stack
                direction={{ base: "column", md: "row" }}
                spacing={{ base: "1.5", md: "8" }}
                justify="space-between"
              >
                <FormLabel variant="inline">Location</FormLabel>
                <Input
                  onChange={(e: any) =>
                    setAccountForm({ ...accountForm, location: e.target.value })
                  }
                  maxW={{ md: "3xl" }}
                  value={accountForm?.location}
                />
              </Stack>
            </FormControl>
            <FormControl id="name">
              <Stack
                direction={{ base: "column", md: "row" }}
                spacing={{ base: "1.5", md: "8" }}
                justify="space-between"
              >
                <FormLabel variant="inline">Team Size</FormLabel>
                <Select
                  onChange={(e: any) =>
                    setAccountForm({ ...accountForm, teamSize: e.target.value })
                  }
                  value={accountForm?.teamSize}
                  maxW={{ md: "3xl" }}
                  placeholder="Select Team Size"
                >
                  <option value="1-1"> 1 - 1</option>
                  <option value="2-10"> 2 - 10</option>
                  <option value="10-50">10 - 50</option>
                  <option value="50+"> 50+</option>
                </Select>
              </Stack>
            </FormControl>
            <FormControl id="name">
              <Stack
                direction={{ base: "column", md: "row" }}
                spacing={{ base: "1.5", md: "8" }}
                justify="space-between"
              >
                <FormLabel variant="inline">Recording</FormLabel>
                <Switch
                  maxW={{ md: "3xl" }}
                  onChange={(e: any) =>
                    setAccountForm({
                      ...accountForm,
                      recording: e.target.checked,
                    })
                  }
                  id="email-alerts"
                  isChecked={accountForm?.recording}
                />
                {/* <Input onChange={(e: any) => setAccountForm({...accountForm, teamSize: e.target.value})} maxW={{ md: '3xl' }} value={accountForm?.teamSize} /> */}
              </Stack>
            </FormControl>
            <FormControl id="name">
              <Stack
                direction={{ base: "column", md: "row" }}
                spacing={{ base: "1.5", md: "8" }}
                justify="space-between"
              >
                <FormLabel variant="inline">Mask Class Name</FormLabel>
                <Input
                  onChange={(e: any) =>
                    setAccountForm({
                      ...accountForm,
                      mask_element: e.target.value,
                    })
                  }
                  maxW={{ md: "3xl" }}
                  value={accountForm?.mask_element}
                />
              </Stack>
            </FormControl>
            <FormControl id="bio">
              <Stack
                direction={{ base: "column", md: "row" }}
                spacing={{ base: "1.5", md: "8" }}
                justify="space-between"
              >
                <Box>
                  <FormLabel variant="inline">Business Description</FormLabel>
                  <FormHelperText mt="0" color="fg.muted">
                    Write a short introduction about you
                  </FormHelperText>
                </Box>
                <Textarea
                  onChange={(e: any) =>
                    setAccountForm({
                      ...accountForm,
                      businessDescription: e.target.value,
                    })
                  }
                  value={accountForm?.businessDescription}
                  maxW={{ md: "3xl" }}
                  rows={5}
                  resize="none"
                />
              </Stack>
            </FormControl>
          </Stack>
        </Stack>
      )}
    </Container>
  );
};
