import { createContext, useContext, useMemo, useEffect, useState } from "react";
import accountService from "../services/account.service";

const defaultState = {
  allUsers: [],
  meta: {
    pageLimit: 0,
    currentPage: 0,
    total_count: 0,
  },
  changeAllUsersFilter: (_query: any) => {},
};

const AllUsersContext = createContext(defaultState);

export const AllUsersProvider = ({ children }: any) => {
  const [allUsers, setAllUsers] = useState<any>([]);
  const [meta, setMeta] = useState<any>({});

  const getAllUsers = async (_query?: any) => {
    const res = await accountService.getAllUsers(_query);
    if (res?.success) {
      setAllUsers(res.users);
      setMeta(res.meta);
    }
  };

  const changeAllUsersFilter = (_query: any) => {
    getAllUsers(_query);
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const value = useMemo(
    () => ({
      allUsers,
      meta,
      changeAllUsersFilter,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allUsers, meta]
  );
  return (
    <AllUsersContext.Provider value={value}>
      {children}
    </AllUsersContext.Provider>
  );
};

export const useAllUsers = () => {
  return useContext(AllUsersContext);
};
