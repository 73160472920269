import { Table } from "../../components/Table/table";
import { useSessions } from "../../context/SessionsContext";
import MasterLayout from "../../layout/MasterLayout";

const Sessions = () => {
  const { sessions } = useSessions();

  return (
    <MasterLayout>
      <Table sessions={sessions} />
    </MasterLayout>
  );
};

export default Sessions;
