import { CreateAccountComponent } from "../../components/Account/CreateAccountComponent"
import MasterLayout from "../../layout/MasterLayout"


const CreateAccount = () => {

    return (
        <MasterLayout>
            <CreateAccountComponent />
        </MasterLayout>
    )
}

export { CreateAccount }