import { ProfileComponent } from "../../components/Profile/ProfileComponent"
import MasterLayout from "../../layout/MasterLayout"


const Profile = () => {

    return (
        <MasterLayout>
            <ProfileComponent />
        </MasterLayout>
    )
}

export { Profile }