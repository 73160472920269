import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Badge,
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { FiEdit2, FiRotateCw, FiTrash2 } from "react-icons/fi";
import { IoArrowDown } from "react-icons/io5";
import { TbLock, TbLockOpen } from "react-icons/tb";
import PhoneInput, { Value } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useAuth } from "../../context/AuthContext";
import { useMembers } from "../../context/MembersContext";
import userService from "../../services/user.service";

interface ITable extends TableProps {
  members: any;
}

const MemberTable = (props: ITable) => {
  const { isOpen, onOpen, onClose }: any = useDisclosure();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  }: any = useDisclosure();
  const [blockUserId, setBlockUserId] = useState<string>("");
  const [admin, setAdmin] = useState<any>();
  const { resendInvite, blockAdmin, unBlockAdmin } = useAuth();
  const { changeMemberFilter, membersMeta } = useMembers();

  const handleBlock = async (id: string) => {
    await blockAdmin(id, {
      blocked_until: new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
      ).toISOString(),
    });

    changeMemberFilter({ page: membersMeta.currentPage });
  };

  const handleUnblock = async (id: string) => {
    await unBlockAdmin(id);
    changeMemberFilter({ page: membersMeta.currentPage });
  };

  return (
    <Table {...props}>
      <Thead>
        <Tr>
          <Th>
            <HStack spacing="3">
              {/* <Checkbox /> */}
              <HStack spacing="1">
                <Text>Name</Text>
                <Icon as={IoArrowDown} color="fg.muted" boxSize="4" />
              </HStack>
            </HStack>
          </Th>
          <Th>Email</Th>
          <Th>Role</Th>
          <Th>Status</Th>
          <Th>Contact Number</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {props?.members.map((member: any) => (
          <Tr key={member.id}>
            <Td>
              <HStack spacing="3">
                {/* <Checkbox /> */}
                <Avatar name={member.name} src={member.avatar} boxSize="10" />
                <Box>
                  <Text fontWeight="medium">{`${member.first_name} ${member.last_name}`}</Text>
                  <Text color="fg.muted">{member.handle}</Text>
                </Box>
              </HStack>
            </Td>
            <Td>
              <Text color="fg.muted">{member?.email}</Text>
            </Td>
            <Td>
              <Badge
                size="sm"
                colorScheme={member.status === "ACTIVE" ? "green" : "red"}
              >
                {member?.role}
              </Badge>
            </Td>
            <Td>
              <Badge
                size="sm"
                colorScheme={
                  member.status === "PENDING"
                    ? "yellow"
                    : member.status === "ACTIVE"
                    ? "green"
                    : "red"
                }
              >
                <Text color="fg.muted">{member?.status}</Text>
              </Badge>
            </Td>
            <Td>
              <Text color="fg.muted">
                {member.phone_number ? member.phone_number : "-"}
              </Text>
            </Td>
            {/* <Td>
              <Text color="fg.muted">
                <Rating defaultValue={member.rating} size="xl" />
              </Text>
            </Td> */}
            <Td>
              <HStack spacing="1">
                {member.status === "PENDING" ? (
                  <Tooltip
                    label="resend invitation"
                    placement="top-start"
                    bg="rgb(49, 151, 149)"
                  >
                    <IconButton
                      icon={<FiRotateCw fontSize="1.25rem" />}
                      variant="tertiary"
                      aria-label="resend invite"
                      onClick={() => resendInvite(member.id)}
                    />
                  </Tooltip>
                ) : (
                  <IconButton
                    icon={<FiRotateCw fontSize="1.25rem" opacity={"0.5"} />}
                    variant="tertiary"
                    aria-label="resend invite"
                    isDisabled={true}
                  />
                )}

                {member.status === "ACTIVE" ? (
                  <Tooltip label={"Block"} placement="top-start" bg="tomato">
                    <IconButton
                      icon={<TbLockOpen fontSize="1.25rem" />}
                      variant="tertiary"
                      aria-label="resend invite"
                      onClick={() => {
                        handleBlock(member.id);
                      }}
                    />
                  </Tooltip>
                ) : member.status === "BLOCKED" ? (
                  <Tooltip
                    label="Unblock"
                    placement="top-start"
                    bg="rgb(49, 151, 149)"
                  >
                    <IconButton
                      icon={<TbLock fontSize="1.25rem" color="tomato" />}
                      variant="tertiary"
                      aria-label="resend invite"
                      onClick={() => {
                        handleUnblock(member.id);
                      }}
                    />
                  </Tooltip>
                ) : (
                  <IconButton
                    icon={<TbLockOpen fontSize="1.25rem" opacity={"0.5"} />}
                    variant="tertiary"
                    aria-label="resend invite"
                    isDisabled={true}
                  />
                )}

                {member.status === "ACTIVE" ? (
                  <IconButton
                    icon={<FiTrash2 fontSize="1.25rem" opacity={"0.5"} />}
                    variant="tertiary"
                    aria-label="Delete member"
                    isDisabled={true}
                  />
                ) : (
                  <Tooltip label="Delete" placement="top-start" bg="red.500">
                    <IconButton
                      icon={<FiTrash2 fontSize="1.25rem" />}
                      variant="tertiary"
                      aria-label="Delete Admin"
                      onClick={() => {
                        // eslint-disable-next-line no-lone-blocks
                        {
                          setBlockUserId(member.id);
                          onOpenDelete();
                        }
                      }}
                    />
                  </Tooltip>
                )}

                {member.status === "ACTIVE" ? (
                  <Tooltip
                    label="Edit"
                    placement="top-start"
                    bg="rgb(49, 151, 149)"
                  >
                    <IconButton
                      icon={<FiEdit2 fontSize="1.25rem" />}
                      variant="tertiary"
                      aria-label="Edit member"
                      onClick={() => {
                        setAdmin(member);
                        onOpen();
                      }}
                    />
                  </Tooltip>
                ) : (
                  <IconButton
                    icon={<FiEdit2 fontSize="1.25rem" opacity={"0.5"} />}
                    variant="tertiary"
                    aria-label="Edit member"
                    isDisabled={true}
                  />
                )}
              </HStack>
            </Td>
          </Tr>
        ))}
      </Tbody>
      <DeleteAlert
        isOpen={isOpenDelete}
        onClose={onCloseDelete}
        userId={blockUserId}
      />
      <AdminEditModal isOpen={isOpen} onClose={onClose} user={admin} />
    </Table>
  );
};

export { MemberTable };

const AdminEditModal = ({ isOpen, onClose, user }: any) => {
  const [userForm, setUserForm] = useState({
    first_name: user?.first_name,
    last_name: user?.last_name,
    email: user?.email,
    phone_number: user?.phone_number,
  });

  const handlePhoneChange = (value: Value) => {
    setUserForm((prevForm) => ({
      ...prevForm,
      phone_number: value || "",
    }));
  };
  const { membersMeta, changeMemberFilter } = useMembers();
  const toast = useToast();
  const toastId = "password-toast";

  useEffect(() => {
    if (user) {
      const { first_name, last_name, email, phone_number } = user;
      setUserForm({ first_name, last_name, email, phone_number });
    }
  }, [user]);

  const handleSubmit = async () => {
    if (toast.isActive(toastId)) {
      toast.close(toastId);
    }

    //To check valid phone number
    if (userForm.phone_number) {
      if (!userForm.phone_number.startsWith("+")) {
        userForm.phone_number = `+91${userForm.phone_number}`;
      }
    }

    const res: any = await userService.updateAdmin(user.id!, userForm);
    if (res.success) {
      toast({
        id: toastId,
        title: "Updated successfully",
        status: "success",
        position: "top-right",
        isClosable: true,
      });
      changeMemberFilter({ page: membersMeta.currentPage });
    } else {
      toast({
        id: toastId,
        title: "Updated Failed",
        status: "error",
        position: "top-right",
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invite User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box as="form" bg="bg.surface">
            <Stack spacing="5" py={{ base: "5", md: "6" }}>
              <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                <FormControl id="firstName">
                  <FormLabel>First Name</FormLabel>
                  <Input
                    onChange={(e: any) =>
                      setUserForm({ ...userForm, first_name: e.target.value })
                    }
                    value={userForm.first_name}
                  />
                </FormControl>
                <FormControl id="lastName">
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    onChange={(e: any) =>
                      setUserForm({ ...userForm, last_name: e.target.value })
                    }
                    value={userForm.last_name}
                  />
                </FormControl>
              </Stack>
              <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                <FormControl id="firstName">
                  <FormLabel>Email</FormLabel>
                  <Tooltip label="Email cant be updated" bg={"red.400"}>
                    <Input value={userForm.email} disabled />
                  </Tooltip>
                </FormControl>
                {/* <FormControl id="lastName">
                <FormLabel>Role</FormLabel>
                <Select onChange={(e: any) => setUserForm({...userForm, role: e.target.value})} value={userForm.role} placeholder='Select Role'>
                    <option value='OWNER'>Owner</option>
                    <option value='DEVELOPER'>Developer</option>
                    <option value='ANALYST'>Analyst</option>
                </Select>
                </FormControl> */}
              </Stack>
              <FormControl id="street">
                <FormLabel>Phone number</FormLabel>
                <PhoneInput
                  defaultCountry="IN"
                  value={userForm.phone_number}
                  onChange={handlePhoneChange}
                  className="signUpPhone"
                  style={{ width: "100%" }}
                />
                {/* <Input onChange={(e: any) => setUserForm({...userForm, phone_number: e.target.value})} value={userForm.phone_number} /> */}
              </FormControl>
            </Stack>
            {/* <Divider /> */}
            {/* <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
                <Button type="submit" variant="primary">
                    Save
                </Button>
            </Flex> */}
          </Box>
        </ModalBody>

        <ModalFooter>
          {/* <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button> */}
          <Button
            onClick={() => {
              handleSubmit();
              onClose();
            }}
            type="submit"
            variant="primary"
          >
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const DeleteAlert = ({ isOpen, onOpen, onClose, userId }: any) => {
  const cancelRef: any = useRef();
  const { membersMeta, changeMemberFilter, deleteMembers } = useMembers();

  const handleDelete = async (id: string) => {
    await deleteMembers(id);
    // getMembers()
    changeMemberFilter({ page: membersMeta.currentPage });
    onClose();
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete Admin
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You can't undo this action afterwards.
            {/* <FormLabel mt={{ base: '4' }}>Delete Admin</FormLabel> */}
            {/* <Box my={{ base: '3' }}> */}
            {/* <DatePickerInput onChange={(e) => setValue(new Date(e).toISOString())} defaultValue={(new Date()).setDate((new Date()).getDate() + 1)} /> */}
            {/* <SingleDatepicker
    name="date-input"
    date={date}
    onDateChange={setDate}
  /> */}
            {/* </Box> */}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Button
              colorScheme="red"
              onClick={() => handleDelete(userId)}
              ml={3}
            >
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
