// interfaces
import {
  IEmailSignInResponse,
  IEmailSignUpResponse,
  IEmailSignInInputs,
  IEmailSignUpInputs,
} from "../interfaces/auth";
// services
import APIService from "./api.service";

const { REACT_APP_BASE_URL } = process.env;

class AuthService extends APIService {
  constructor() {
    super(REACT_APP_BASE_URL || "http://localhost:8000");
  }

  async emailSignIn(data: IEmailSignInInputs): Promise<IEmailSignInResponse> {
    return this.post("admins/sign_in/", data, { headers: {} })
      .then((response) => {
        this.setAccessToken(response?.data?.token);
        return response?.data;
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
        return error?.response?.data;
      });
  }

  async emailSignUp(data: IEmailSignUpInputs): Promise<IEmailSignUpResponse> {
    return this.post("/api/sign-up/", data, { headers: {} })
      .then((response) => {
        this.setAccessToken(response?.data?.access_token);
        return response?.data;
      })
      .catch((error) => {
        // throw error?.response?.data;
        return error?.response?.data;
      });
  }

  async acceptInvitation(token: string, data: any): Promise<any> {
    return this.post(`/admins/${token}/accept_invitation`, data, {
      headers: {},
    })
      .then((response) => {
        this.setAccessToken(response?.data?.access_token);
        return response?.data;
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
        return error?.response?.data?.message;
        // throw error?.response?.data;
      });
  }

  async forgotPassword(data: any) {
    return this.post("/admins/forget_password", data, { headers: {} })
      .then((response) => {
        return response?.data;
      })
      .catch(async (error) => {
        // throw error?.response?.data;
        return error?.response?.data;
      });
  }

  async resetPassword(data: any) {
    return this.post(`admins/reset_password`, data, { headers: {} })
      .then((response) => {
        return response?.data;
      })
      .catch(async (error) => {
        // throw error?.response?.data;
        return error?.response?.data?.message;
      });
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AuthService();
