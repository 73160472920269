export const buildQueryParams = (params: any, path?: string, router?: any) => {
  if (params) {
    Object.keys(params).forEach((key) => {
      const value = params[key];
      if (
        value === null ||
        value === "null" ||
        value === "" ||
        value === undefined ||
        (typeof value === 'string' && value.length < 1) ||
        typeof value === 'function' ||
        (typeof value === 'string' && value.includes("function"))
      ) {
        delete params[key];
      }
    });
    let rating: Array<number> = [];
    if (params?.rating?.length) {
      rating = [...params.rating];
      delete params.rating;
    }
    const searchParams = new URLSearchParams(params);
    rating.map((rate) => searchParams.append("rating", rate.toString()));
    searchParams.toString();
    return router
      ? router.push(`${path}?${searchParams.toString()}`)
      : searchParams.toString();
  }
};

export const accountFilterObject = (searchParams: any) => {
  return{
    page: searchParams?.get("page") ?? 1,
    limit: searchParams?.get("limit") ?? 10,
    sort: searchParams?.get("sort") ?? "-createdAt",
    status: searchParams?.get("status") ?? "",
    search: searchParams?.get("search") ?? "",
    email: searchParams?.get("email") ?? "",
    teamSize: searchParams?.get("teamSize") ?? "",
    accountPlan: searchParams?.get("accountPlan") ?? "",
    companyName: searchParams?.get("companyName") ?? "",
  }
}