import {
    Avatar,
    Badge,
    Box,
    HStack,
    Icon,
    IconButton,
    Table,
    TableProps,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
    FormControl,
    FormLabel,
    Input,
    useDisclosure,
    useToast,
    Tooltip,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Stack,
    Select,
    ModalFooter
  } from '@chakra-ui/react'
  import { FiEdit2, FiTrash2 } from 'react-icons/fi'
  import { IoArrowDown } from 'react-icons/io5'
  import {  useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useAccountUsers } from '../../context/AccountUsersContext'
import PhoneInput, { Value } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

  interface ITable extends TableProps {
    users: any
  }
  
  const UsersTable = (props: ITable) => {
    const { deleteUserById, getAccountUsers, accountId } = useAccountUsers()
    const { isOpen, onOpen, onClose }: any = useDisclosure()
    const { isOpen: isOpenEdit, onOpen: onOpenEdit, onClose: onCloseEdit }: any = useDisclosure()
    const { id } = useParams()
    const toast = useToast();
    const toastId = 'password-toast';
    const [accUser,setUser]=useState<any>()

    const handleDelete = async (userId: string) => {
      if (toast.isActive(toastId)) {
        toast.close(toastId);
      }
        const res: any = await deleteUserById(id!, userId)
        getAccountUsers(accountId)
        if(res.success){
              toast({
                    id: toastId,
                    title: 'Deleted successfully',
                    status: 'success',
                    position: 'top-right',
                    isClosable: true,
                    duration:3000
                  });
        }else{
             toast({
                   id: toastId,
                    title: 'delete failed',
                    status: 'error',
                    position: 'top-right',
                    isClosable: true,
                    duration:3000
                  });
        }
        onClose()
    }
    return (
    <Table {...props}>
      <Thead>
        <Tr>
          <Th>
            <HStack spacing="3">
              <HStack spacing="1">
                <Text>Name</Text>
                <Icon as={IoArrowDown} color="fg.muted" boxSize="4" />
              </HStack>
            </HStack>
          </Th>
          <Th>Role</Th>
          <Th>Status</Th>
          <Th>Last Login</Th>
          <Th>Joined Date</Th>
          <Th>Contact Number</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {
            props.users 
        .map((user: any) => (
          <Tr key={user.id}>
            <Td>
              <HStack spacing="3">
                <Avatar name={user.first_name + ' ' + user.last_name} src={user.avatarUrl} boxSize="10" />
                <Box>
                  <Text fontWeight="medium">{user.first_name + ' ' + user.last_name}</Text>
                  <Text color="fg.muted">{user?.email}</Text>
                </Box>
              </HStack>
            </Td>
            <Td>
              <Badge size="sm" colorScheme={user.status === 'ACTIVE' ? 'green' : 'red'}>
                {user?.role}
              </Badge>
            </Td>
            <Td>
              <Text color="fg.muted">{user?.status}</Text>
            </Td>
            <Td>{new Date(user?.last_active_at).toLocaleString('en-GB', { weekday:"long", year:"numeric", month:"short", day:"numeric", hour: 'numeric', minute: 'numeric', hour12: true })}</Td>
            <Td>{new Date(user?.createdAt).toLocaleString('en-GB', { weekday:"long", year:"numeric", month:"short", day:"numeric", hour: 'numeric', minute: 'numeric', hour12: true })}</Td>
            <Td>
              <Text color="fg.muted">{user?.phone_number}</Text>
            </Td>
            <Td>
              <HStack spacing="1">
                <IconButton
                  icon={<FiTrash2 fontSize="1.25rem" />}
                  variant="tertiary"
                  aria-label="Delete member"
                  onClick={()=>{setUser(user)
                    onOpen()}}
                />
                {/* {user.status==='ACTIVE'?<IconButton
                  icon={<FiEdit2 fontSize="1.25rem" />}
                  variant="tertiary"
                  aria-label="Edit member"
                  onClick={onOpenEdit}
                />:<Tooltip label='User not active' placement='top-start' bg="red.400"><IconButton
                icon={<FiEdit2 fontSize="1.25rem" opacity={"0.5"}/>}
                variant="tertiary"
                aria-label="Edit member"
                isDisabled={true}
              /></Tooltip>} */}
                <IconButton
                  icon={<FiEdit2 fontSize="1.25rem" />}
                  variant="tertiary"
                  aria-label="Edit member"
                  onClick={()=>{setUser(user) 
                    onOpenEdit()}}
                />
              </HStack>
            </Td>
            
            <DeleteAlert isOpen={isOpen} onOpen={onOpen} onClose={onClose} handleDelete={handleDelete} userId={accUser?accUser.id:""} />
          </Tr>
        ))}
      </Tbody>
        <UserEditModel isOpen={isOpenEdit} onClose={onCloseEdit} user={accUser} />
    </Table>
  )
}

  export { UsersTable }

  const DeleteAlert = ({ isOpen, onOpen, onClose, handleDelete, userId }: any) => {
    const cancelRef: any = React.useRef()
    const [value, setValue] = React.useState('')
  
    return (
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Delete User
              </AlertDialogHeader>
  
              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>
                
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  No
                </Button>
                <Button colorScheme='red' onClick={() => handleDelete(userId)} ml={3}>
                  Yes
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
    )
  }

  const UserEditModel = ({ isOpen, onClose, user }: any) => {
    const intitalform = {
        first_name: user?.first_name,
        last_name: user?.last_name ,
        email: user?.email ,
        role: user?.role ,
        phone_number: user?.phone_number
    }

    const toast = useToast();
    const toastId = 'password-toast';

    useEffect(() => {
      if (user) {
        const { first_name, last_name, email,role, phone_number } = user
        setUserForm({ first_name, last_name, email,role, phone_number })
      }
    }, [user])
    const [userForm, setUserForm] = useState(intitalform)
    const {updateUserByAccountId,changeUserAccount,usersMeta}=useAccountUsers()
    const { id } = useParams()

    const handlePhoneChange = (value: Value) => {
        setUserForm((prevForm) => ({
          ...prevForm,
          phone_number: value || '',
        }));
      };

    const handleSubmit = async () => {
        if(userForm.email&&userForm.first_name&&userForm.last_name){
           if(userForm.phone_number){ if(!userForm.phone_number.startsWith('+')){
            userForm.phone_number=`+91${userForm.phone_number}`
          }}
          if (toast.isActive(toastId)) {
            toast.close(toastId);
          }

            //update user in account
            const res:any =await updateUserByAccountId(id!, user.id, userForm)
            if(res.success){
              toast({
                id:toastId,
                title: 'User updated successfully',
                status: 'success',
                position: 'top-right',
                isClosable: true,
                duration:3000
              })
                changeUserAccount({ page: usersMeta.currentPage },id!)
                onClose()

            }else{
              toast({
                id:toastId,
                title: res,
                status: 'error',
                position: 'top-right',
                isClosable: true,
                duration:3000
              })
            }
    }}

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update User
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Box as="form" bg="bg.surface" >
            <Stack spacing="5" py={{ base: '5', md: '6' }}>
            <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                <FormControl id="firstName">
                <FormLabel>First Name</FormLabel>
                <Input onChange={(e: any) => setUserForm({...userForm, first_name: e.target.value})} value={userForm.first_name} />
                </FormControl>
                <FormControl id="lastName">
                <FormLabel>Last Name</FormLabel>
                <Input onChange={(e: any) => setUserForm({...userForm, last_name: e.target.value})} value={userForm.last_name} />
                </FormControl>
            </Stack>
            <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                <FormControl id="firstName">
                <FormLabel>Email</FormLabel>
               <Tooltip label="Email cant be updated" placement='top' bg={'red.400'} ><Input  value={userForm.email} disabled/></Tooltip>
                </FormControl>
               <FormControl id="lastName">
                <FormLabel>Role</FormLabel>
                <Select onChange={(e: any) => setUserForm({...userForm, role: e.target.value})} value={userForm.role} placeholder='Select Role'>
                    <option value='OWNER'>Owner</option>
                    <option value='DEVELOPER'>Developer</option>
                    <option value='ANALYST'>Analyst</option>
                </Select>
                </FormControl>
            </Stack>
            <FormControl id="street">
                <FormLabel>Phone number(Optional)</FormLabel>
                <PhoneInput
                  defaultCountry="IN"
                  value={userForm.phone_number}
                  onChange={handlePhoneChange}
                  className="signUpPhone"
                  style={{ width: '100%' }}
                />              
            </FormControl>
            </Stack>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleSubmit} type="submit" variant="primary">
                Update
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
}
