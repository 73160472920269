
import APIService from "./api.service";

const { REACT_APP_BASE_URL } = process.env;

const filterQueryParams = (params: any = {}) => {
  Object.keys(params).forEach((key) => {
    if (params[key] === null || params[key]?.length < 1 || params[key] === "") {
      delete params[key];
    }
  });
  let rating: Array<number> = [];
  if (params?.rating?.length) {
    rating = [...params.rating];
    delete params.rating;
  }
  const searchParams = new URLSearchParams(params);
  rating.map((rate) => searchParams.append("rating", rate.toString()));
  return searchParams.toString();
};

class UserService extends APIService {
  constructor() {
    super(REACT_APP_BASE_URL || "http://localhost:8000");
  }

  async getUser(): Promise<any> {
    return this.get("admins/me", { headers: {} })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
        // throw error?.response?.data?.message;
        // return error?.response?.data;
      });
  }

  async changePassword(data: any): Promise<any> {
    return this.post("admins/change_password", data, {})
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
        return { error: error?.response?.data?.message };
        // throw error?.response?.data?.message;
      });
  }

  async getUsers(meta?: any): Promise<any> {
    return this.get(`admins?${filterQueryParams(meta)}`, {})
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
        // throw error?.response?.data?.message;
      });
  }

  async inviteAdmin(params: any): Promise<any> {
    return this.post(`admins/invite`, params, {})
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
        return error?.response?.data?.message;
        // throw error?.response?.data?.message;
      });
  }

  async updateAdmin(id: string, params: any): Promise<any> {
    return this.put(`admins/${id}`, params, {})
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
        return error?.response?.data?.message;
      });
  }

  async blockAdmin(id: string, params: any): Promise<any> {
    return this.post(`admins/${id}/block`, params, {})
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
        return error?.response?.data?.message;
        // throw error?.response?.data?.message;
      });
  }

  async unBlockAdmin(id: string): Promise<any> {
    return this.post(`admins/${id}/unblock`, {})
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
        return error?.response?.data?.message;
        // throw error?.response?.data?.message;
      });
  }

  async deleteAdmin(id: string) {
    return this.delete(`admins/${id}`, {})
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
        return error?.response?.data?.message;
      });
  }

  async uploadAvatar(file: FormData) {
    return this.post(`admins/avatar`, file, {
      contentType: "multipart/form-data",
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.error("check error", error?.response?.data);
        return error?.response;
      });
  }

  async resendInvitation(id: string) {
    return this.post(`admins/${id}/resend_invitation`, {})
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.error(error?.response?.data);
        return error?.response?.data?.message;
      });
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new UserService();
