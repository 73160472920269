import {
  Avatar,
  Badge,
  Box,
  HStack,
  Icon,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { IoArrowDown } from "react-icons/io5";

interface ITable extends TableProps {
  users: any;
}

const AllUsersTable = (props: ITable) => {
  return (
    <Table {...props}>
      <Thead>
        <Tr>
          <Th>
            <HStack spacing="3">
              {/* <Checkbox /> */}
              <HStack spacing="1">
                <Text>Name</Text>
                <Icon as={IoArrowDown} color="fg.muted" boxSize="4" />
              </HStack>
            </HStack>
          </Th>
          <Th>Company Name</Th>
          <Th>Role</Th>
          <Th>Status</Th>
          <Th>Last Login</Th>
          <Th>Joined Date</Th>
          <Th>Contact Number</Th>
        </Tr>
      </Thead>
      <Tbody>
        {
          // (
          props.users
            // || users)
            .map((user: any) => (
              <Tr key={user.id}>
                <Td>
                  <HStack spacing="3">
                    {/* <Checkbox /> */}
                    <Avatar
                      name={user.first_name + " " + user.last_name}
                      src={user.avatarUrl}
                      boxSize="10"
                    />
                    <Box>
                      <Text fontWeight="medium">
                        {user.first_name + " " + user.last_name}
                      </Text>
                      <Text color="fg.muted">{user?.email}</Text>
                    </Box>
                  </HStack>
                </Td>
                <Td>{user?.account?.name}</Td>
                <Td>
                  <Badge
                    size="sm"
                    colorScheme={user.status === "active" ? "green" : "red"}
                  >
                    {user?.role}
                  </Badge>
                </Td>
                <Td>
                  <Text color="fg.muted">{user?.status}</Text>
                </Td>
                <Td>
                  {user?.last_active_at
                    ? new Date(user?.last_active_at).toLocaleString("en-GB", {
                        weekday: "long",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })
                    : ""}
                </Td>
                <Td>
                  {new Date(user?.createdAt).toLocaleString("en-GB", {
                    weekday: "long",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })}
                </Td>
                <Td>
                  <Text color="fg.muted">{user?.phone_number}</Text>
                </Td>
                {/* <Td>
              <Text color="fg.muted">
                <Rating defaultValue={member.rating} size="xl" />
              </Text>
            </Td> */}
                {/* <Td>
              <HStack spacing="1">
                <IconButton
                  icon={<FiTrash2 fontSize="1.25rem" />}
                  variant="tertiary"
                  aria-label="Delete member"
                  onClick={onOpen}
                />
                <IconButton
                  icon={<FiEdit2 fontSize="1.25rem" />}
                  variant="tertiary"
                  aria-label="Edit member"
                  onClick={onOpenEdit}
                />
              </HStack>
            </Td> */}
              </Tr>
            ))
        }
      </Tbody>
    </Table>
  );
};

export { AllUsersTable };
