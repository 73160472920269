import MasterLayout from "../../layout/MasterLayout"
import { Table } from "../../components/Table/table"
import { useAccounts } from "../../context/AccountsContext"

const Accounts = () => {
    const { accounts } = useAccounts()

    return (
        <MasterLayout>
            <Table accounts={accounts} />
        </ MasterLayout>
    )
}

export default Accounts