import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  StackDivider,
  Text,
  useToast,
} from "@chakra-ui/react";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import React, { useState } from "react";
import { useAccount } from "../../context/AccountContext";

export const AccountConfigComponent = () => {
  const [config, setConfig] = React.useState<any>({});
  const [plan, setPlan] = React.useState<any>({});
  const { account, updateAccountById }: any = useAccount();
  const [date, setDate] = useState(
    new Date(new Date().setFullYear(new Date().getFullYear() + 100))
  );
  const toast = useToast();
  const toastId = 'password-toast';


  React.useEffect(() => {
    if (account) {
      setConfig({
        expiry: account?.meta?.expiry,
        blockAcccountExpiryDate: account?.blockAcccountExpiryDate,
      });
      setPlan({
        agent: parseInt(account?.plan?.agent),
        session: account?.plan?.session,
        retention: account?.plan?.retention,
        recording: account?.plan?.recording,
      });
    }
  }, [account]);

  const handleSubmit = async () => {
    const {
      agentsCount,
      expiry,
      sessionsCount,
      storageRetention,
      recordingsCount,
      blockAcccountExpiryDate,
    } = config;

    if (toast.isActive(toastId)) {
      toast.close(toastId);
    }

    const res:any = await updateAccountById(account._id?account._id:account.id, {
      meta: {
        ...account.meta,
        agentsCount,
        expiry,
        sessionsCount,
        storageRetention,
        recordingsCount,
        blockAcccountExpiryDate,
      },
      plan,
    });
    if(res?.success){
      toast({
        id: toastId,
                title: 'Config updated successfully',
                status: 'success',
                position: 'top-right',
                isClosable: true,
                duration:3000
              });
    }else{
      toast({
        id: toastId,
                title: 'Config updated failed',
                status: 'error',
                position: 'top-right',
                isClosable: true,
                duration:3000
              });
    }
  };

  return (
    <Container maxW="container.xl" py={{ base: "4", md: "8" }}>
      <Stack spacing="5" mb={{ base: "4" }}>
        <Stack
          spacing="4"
          direction={{ base: "column", sm: "row" }}
          justify="space-between"
        >
          <Flex>
            <Text textStyle="lg" fontWeight="medium">
              Account Configuration
            </Text>
          </Flex>
          <Flex>
            <Button
              onClick={() => handleSubmit()}
              variant="primary"
              alignSelf="start"
            >
              Save
            </Button>
          </Flex>
        </Stack>
        <Divider />
        <Stack spacing="5" divider={<StackDivider />}>
          <FormControl id="name">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <FormLabel color={"red"} variant="inline">
                Account expiry date
              </FormLabel>
              <Box my={{ base: "3" }}>
                <SingleDatepicker
                  name="date-input"
                  date={date}
                  onDateChange={(newDate) => {
                    setDate(newDate);
                    setConfig({
                      ...config,
                      expiry: new Date(newDate).toISOString(),
                    });
                  }}
                />
              </Box>
              {/* <Input onChange={(e: any) => setConfig({...config, expiry: e.target.value})} maxW={{ md: '3xl' }} value={config?.expiry} /> */}
            </Stack>
          </FormControl>
          <FormControl bgColor={"gray.100"} id="name">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <FormLabel variant="inline">Block Account expiry date</FormLabel>
              <Box my={{ base: "3" }}>
                <SingleDatepicker
                  name="date-input"
                  date={date}
                  onDateChange={(newDate) => {
                    setDate(newDate);
                    setConfig({
                      ...config,
                      expiry: new Date(newDate).toISOString(),
                    });
                  }}
                />
              </Box>
              {/* <Input onChange={(e: any) => setConfig({...config, blockAcccountExpiryDate: e.target.value})} maxW={{ md: '3xl' }} value={config?.blockAcccountExpiryDate} /> */}
            </Stack>
          </FormControl>
        </Stack>
      </Stack>
      <Stack spacing="5">
        <Stack
          spacing="4"
          direction={{ base: "column", sm: "row" }}
          justify="space-between"
        >
          <Flex>
            <Text textStyle="lg" fontWeight="medium">
              Plan
            </Text>
          </Flex>
        </Stack>
        <Divider />
        <Stack spacing="5" divider={<StackDivider />}>
          <FormControl id="name">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <FormLabel variant="inline">Number of Agents / month</FormLabel>
              <Input
                onChange={(e: any) =>
                  setPlan({ ...plan, agent: parseInt(e.target.value)||0 })
                }
                maxW={{ md: "3xl" }}
                value={plan?.agent}
              />
            </Stack>
          </FormControl>
          <FormControl id="name">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <FormLabel variant="inline">
                Number of Sessions / agent / month
              </FormLabel>
              <Input
                onChange={(e: any) =>
                  setPlan({ ...plan, session: e.target.value })
                }
                maxW={{ md: "3xl" }}
                value={plan?.session}
              />
            </Stack>
          </FormControl>
          <FormControl id="name">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <FormLabel variant="inline">
                Storage Retention Duration in days
              </FormLabel>
              <Input
                onChange={(e: any) =>
                  setPlan({ ...plan, retention: e.target.value })
                }
                maxW={{ md: "3xl" }}
                value={plan?.retention}
              />
            </Stack>
          </FormControl>
          <FormControl id="name">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <FormLabel variant="inline">
                Number of Recorded Sessions / month
              </FormLabel>
              <Input
                onChange={(e: any) =>
                  setPlan({ ...plan, recording: e.target.value })
                }
                maxW={{ md: "3xl" }}
                value={plan?.recording}
              />
            </Stack>
          </FormControl>
        </Stack>
      </Stack>
    </Container>
  );
};
