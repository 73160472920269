import { useToast } from "@chakra-ui/react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { IAccount } from "../interfaces/IAccount";
import accountService from "../services/account.service";
import { buildQueryParams } from "../utils";

const defaultState = {
  accounts: [],
  getAccountById: (id: string) => {},
  getAccountUsers: (id: string) => {},
  regenerateApiKey: (id: string) => {},
  deleteUserById: (id: string, userId: string) => {},
  accountsMeta: {
    pageLimit: 0,
    currentPage: 0,
    total_count: 0,
  },
  usersMeta: {
    pageLimit: 0,
    currentPage: 0,
    total_count: 0,
  },
  inviteUser: (id: string, params: any) => {},
  updateUserByAccountId: (accountId: string, userId: string, params: any) => {},
  getAccounts: (data: any) => {},
  blockAccount: (id: string, payload: any) => {},
  unBlockAccount: (id: string) => {},
  deleteAccount: (id: string) => {},
};

const AccountsContext = createContext(defaultState);

export const AccountsProvider = ({ children }: any) => {
  const [accounts, setAccounts] = useState<IAccount[] | any>([]);
  const [accountsMeta, setAccountsMeta] = useState<any>([]);
  const [usersMeta, setUsersMeta] = useState<any>([]);
  const toast = useToast();

  const inviteUser = async (id: string, params: any) => {
    const res = await accountService.inviteUser(id, params);
    if (res?.success) {
      toast({
        title: `${params.first_name} ${params.last_name} Invited`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } else {
      toast({
        title: "Validation Error",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
        description: res[0],
      });
    }

    return res;
  };

  const getAccounts = async (_meta?: any) => {
    if (buildQueryParams(_meta)) {
      const res = await accountService.getAccounts(buildQueryParams(_meta));
      if (res?.success) {
        setAccounts(res.account);
        setAccountsMeta(res.meta);
      }
    }
  };

  const getAccountById = async (id: string) => {
    const res = await accountService.getAccountById(id);
    if (res?.success) {
      return res.account;
    }
    return null;
  };

  const blockAccount = async (id: string, payload: any) => {
    const res = await accountService.blockAccount(id, payload);
    if (res?.success) {
      toast({
        title: "Account Blocked",
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }
    return res;
  };

  const unBlockAccount = async (id: string) => {
    const res = await accountService.unblockAccount(id);
    if (res?.success) {
      toast({
        title: "Account Un-Blocked",
        status: "success",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
    }
    return res;
  };

  const regenerateApiKey = async (id: string) => {
    const res = await accountService.regenerateApiKey(id);
    if (res?.success) {
      return res.account;
    }
    return null;
  };

  const getAccountUsers = async (id: string) => {
    const res = await accountService.getAccountUsers(id);
    if (res?.success) {
      setUsersMeta(res.meta);
      return res.users;
    }
    return [];
  };

  const deleteUserById = async (id: string, userId: string) => {
    const res = await accountService.deleteUserByAccountId(id, userId);
    if (res?.success) {
      return res?.success;
    }
    return null;
  };

  const updateUserByAccountId = async (
    accountId: string,
    userId: string,
    params: any
  ) => {
    const res = await accountService.updateUserByAccountId(
      accountId,
      userId,
      params
    );
    return res;
  };

  const deleteAccount = async (accountId: string) => {
    const res = await accountService.deleteAccount(accountId);
    return res;
  };

  useEffect(() => {
    getAccounts();
  }, []);

  const value = useMemo(
    () => ({
      accounts,
      getAccountById,
      getAccountUsers,
      regenerateApiKey,
      deleteUserById,
      usersMeta,
      accountsMeta,
      inviteUser,
      updateUserByAccountId,
      getAccounts,
      blockAccount,
      unBlockAccount,
      deleteAccount,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accounts]
  );
  return (
    <AccountsContext.Provider value={value}>
      {children}
    </AccountsContext.Provider>
  );
};

export const useAccounts = () => {
  return useContext(AccountsContext);
};
