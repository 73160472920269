import { Navigate, Route, Routes } from "react-router-dom";
import { AcceptInvitation } from "../pages/Authentication/AcceptInvitation";
import { ForgetPassword } from "../pages/Authentication/ForgetPassword";
import { Login } from "../pages/Authentication/Login";
import { ResetPassword } from "../pages/Authentication/ResetPassword";

export const AuthRoutes = () => (
  // <AuthProvider>
  <Routes>
    <Route path="/*" element={<Navigate to="/login" />} />
    <Route path="/login" element={<Login />} />
    {/* <Route path="/signup" element={<Signup />} /> */}
    <Route path="/accept_invitation" element={<AcceptInvitation />} />
    <Route path="/forget_password" element={<ForgetPassword />} />
    <Route path="/reset_password" element={<ResetPassword />} />
  </Routes>
  // </AuthProvider>
);
