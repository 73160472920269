import { Flex, Stack } from "@chakra-ui/react";
import { FiHome, FiUsers } from "react-icons/fi";
import { RiAdminLine } from "react-icons/ri";
// import { Logo } from './Logo'
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NavButton } from "../components/Button/NavButton";

export const Sidebar = ({ children }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (
      location.pathname.includes("/accounts") ||
      location.pathname.includes("/users") ||
      location.pathname.includes("/sessions")
    ) {
      setFlag(true);
    }
  }, [location.pathname]);

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  return (
    <Flex as="section" minH="100vh" bg="bg.canvas">
      <Flex
        flex="1"
        bg="bg.surface"
        boxShadow="sm"
        maxW={{ base: "full", sm: "xs" }}
        py={{ base: "6", sm: "8" }}
        px={{ base: "4", sm: "6" }}
      >
        <Stack justify="space-between" spacing="1" width="full">
          <Stack spacing="8" shouldWrapChildren>
            {/* <Logo /> */}
            <Stack spacing="1">
              <NavButton
                label="Dashboard"
                icon={FiHome}
                onClick={() => navigate("/dashboard")}
                aria-current={
                  location.pathname.includes("dashboard") ? "page" : "false"
                }
              />
              <NavButton
                label="Admins"
                icon={RiAdminLine}
                onClick={() => {
                  navigate("/members");
                }}
                aria-current={
                  location.pathname.includes("members") ? "page" : "false"
                }
              />
              <NavButton
                label="Accounts"
                icon={FiUsers}
                onClick={() => handleNavigation("/accounts")}
                aria-current={
                  location.pathname.includes("accounts") ? "page" : "false"
                }
              />
              {/* {flag&&(<Stack spacing='1' pl='6'> <NavButton label="Users" icon={FiHome} onClick={() => handleNavigation('/users')} aria-current={location.pathname.includes('users') ? "page" : 'false'} />
              <NavButton label="Sessions" icon={FiHome} onClick={() => handleNavigation('/sessions')} aria-current={location.pathname.includes('sessions') ? "page" : 'false'} />
                
              </Stack>)} */}
            </Stack>
            {/* <Stack>
            <Text textStyle="sm" color="fg.subtle" fontWeight="medium">
              Media
            </Text>
            <Stack spacing="1">
              <NavButton label="Movies" icon={FiFilm} />
              <NavButton label="Pictures" icon={FiCamera} />
              <NavButton label="Music" icon={FiMusic} />
              <NavButton label="Podcasts" icon={FiMic} />
            </Stack>
          </Stack> */}
            {/* <Stack>
            <Text textStyle="sm" color="fg.subtle" fontWeight="medium">
              Social
            </Text>
            <Stack spacing="1">
              <NavButton label="Twitter" icon={FiTwitter} />
              <NavButton label="Instagram" icon={FiInstagram} />
              <NavButton label="Linkedin" icon={FiLinkedin} />
            </Stack>
          </Stack> */}
          </Stack>
        </Stack>
      </Flex>
      {children}
    </Flex>
  );
};
