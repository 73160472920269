  // services
  import APIService from './api.service';
  
  const { REACT_APP_USER_BASE_URL } = process.env;
  
  class RolesService extends APIService {
    constructor() {
      super(REACT_APP_USER_BASE_URL || 'http://localhost:8000');
    }

    // {
    //     "name": "string",
    //     "module": "string",
    //     "features": [
    //       "string"
    //     ]
    //   }

    async createRole(params: any): Promise<any> {
        return this.post('role/create', params, {})
          .then((response) => {
            return response?.data;
          })
          .catch((error) => {
            console.error(error?.response?.data?.message)
            // throw error?.response?.data?.message;
          });
      }

      async updateRole(params: any): Promise<any> {
        return this.put('role', params, {})
          .then((response) => {
            return response?.data;
          })
          .catch((error) => {
            console.error(error?.response?.data?.message)
            // throw error?.response?.data?.message;
          });
      }
  
    async getRoleAccess(): Promise<any> {
      return this.get('role/access', {})
        .then((response) => {
          return response?.data;
        })
        .catch((error) => {
          console.error(error?.response?.data?.message)
          // throw error?.response?.data?.message;
        });
    }

    async getRoleConfig(): Promise<any> {
        return this.get('role/user/config', {})
          .then((response) => {
            return response?.data;
          })
          .catch((error) => {
            console.error(error?.response?.data?.message)
            // throw error?.response?.data?.message;
          });
      }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new RolesService()