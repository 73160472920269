import { AccountComponent } from "../../components/Account/AccountComponent";
import MasterLayout from "../../layout/MasterLayout";

const Account = () => {
  return (
    <MasterLayout>
      <AccountComponent />
    </MasterLayout>
  );
};

export { Account };
