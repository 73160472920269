import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import accountService from "../services/account.service";

const defaultState = {
  sessions: [],
  getSessionByAccount: (id: string) => {},
  sessionMeta: {
    pageLimit: 0,
    currentPage: 0,
    total_count: 0,
  },
  sessionId: "",
  changeSessionAccount: (_meta: any, id: string) => {},
};

const SessionsContext = createContext(defaultState);

export const SessionsProvider = ({ children }: any) => {
  const [sessions, setSessions] = useState<any>([]);
  const [sessionMeta, setSessionMeta] = useState<any>([]);
  const [sessionId, setSessionId] = useState<any>();
  const { id } = useParams();

  const changeSessionAccount = (_meta: any, id: string) => {
    // console.log(_meta)
    let meta: any = {
      page: _meta.currentPage || _meta.page,
      limit: _meta.pageLimit || 10,
      sort: "-createdAt",
    };

    if (_meta.sort) {
      meta.sort = _meta.sort;
    }
    getSessionByAccount(id, meta);
  };

  const getSessionByAccount = async (id: string, meta?: any) => {
    const res = await accountService.getSessionByAccount(id, meta);
    if (res?.success) {
      setSessionMeta(res.meta);
      setSessions(res.sessions);
      setSessionId(id);
    }
  };

  useEffect(() => {
    // getSessions()
    getSessionByAccount(id!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({
      sessions,
      sessionMeta,
      sessionId,
      getSessionByAccount,
      changeSessionAccount,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sessions]
  );
  return (
    <SessionsContext.Provider value={value}>
      {children}
    </SessionsContext.Provider>
  );
};

export const useSessions = () => {
  return useContext(SessionsContext);
};
