import { Table } from "../../components/Table/table";
import { useMembers } from "../../context/MembersContext";
import MasterLayout from "../../layout/MasterLayout";

const Members = () => {
  const { members } = useMembers();

  return (
    <MasterLayout>
      <Table members={members} />
    </MasterLayout>
  );
};

export default Members;
