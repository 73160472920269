import React from "react"
import { Sidebar } from "../components/Sidebar"
import { Header } from "../components/Header/header"


const MasterLayout = ({ children }: any) => {

    return (
        <React.Fragment>
            <Header />
            <Sidebar children={children} />
        </React.Fragment>
    )
}

export default MasterLayout