import {
  Box,
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Logo, PasswordField } from "../../components";
import { ConfirmPasswordField } from "../../components/ConfirmPasswordField";
import { useAuth } from "../../context/AuthContext";

export const ResetPassword = () => {
  const context = useAuth();
  const toast = useToast();
  const toastId = "password-toast";

  const navigate = useNavigate();
  const passwordRef: any = useRef<any>(null);
  const confirmPasswordRef: any = useRef<any>(null);
  const urlToken = new URLSearchParams(window.location.search);
  const token = urlToken.get("token");

  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState<
    string | null
  >(null);

  const validatePassword = (password: string) => {
    if (!password) {
      return "Password is required";
    } else if (password.length < 8) {
      return "Password is too short - should be 8 characters minimum";
    } else if (!/[A-Z]/.test(password)) {
      return "Password must contain at least one capital letter";
    } else if (!/[a-z]/.test(password)) {
      return "Password must contain at least one lowercase letter";
    } else if (!/[0-9]/.test(password)) {
      return "Password must contain at least one number";
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      return "Password must contain at least one special character";
    } else {
      return null;
    }
  };

  const handlePasswordChange = () => {
    const password = passwordRef.current?.value || "";
    const error = validatePassword(password);
    setPasswordError(error);
    validateConfirmPassword(); // To sync both password and confirmPassword validation
  };

  const validateConfirmPassword = () => {
    const password = passwordRef.current?.value || "";
    const confirmPassword = confirmPasswordRef.current?.value || "";
    if (confirmPassword !== password) {
      setConfirmPasswordError("Passwords do not match");
    } else {
      setConfirmPasswordError(null);
    }
  };

  const handleSubmit = async () => {
    const password = passwordRef.current?.value || "";
    const confirmPassword = confirmPasswordRef.current?.value || "";

    const passwordValidationError = validatePassword(password);
    setPasswordError(passwordValidationError);
    validateConfirmPassword();

    if (!passwordValidationError && !confirmPasswordError) {
      const res: any = await context.resetPassword({
        token: token,
        password: password,
        password_confirmation: confirmPassword,
      });

      if (toast.isActive(toastId)) {
        toast.close(toastId);
      }

      if (res?.success) {
        toast({
          id: toastId,
          title: "Password reset successfully",
          status: "success",
          position: "top-right",
          isClosable: true,
        });
        navigate("/");
      } else {
        // Handle error response if necessary
        toast({
          id: toastId,
          title: "reset password failed",
          status: "error",
          position: "top-right",
          isClosable: true,
        });
      }
    }
  };

  return (
    <Container
      maxW="lg"
      py={{ base: "12", md: "24" }}
      px={{ base: "0", sm: "8" }}
    >
      <Stack spacing="8">
        <Stack spacing="6">
          <Logo />
          <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
            <Heading size={{ base: "xs", md: "sm" }}>
              Setup Password for your account
            </Heading>
            <HStack spacing="1" justify="center">
              <Text color="fg.muted">Already have an account?</Text>
              <Button
                variant="text"
                size="lg"
                onClick={() => navigate("/login")}
              >
                Login
              </Button>
            </HStack>
          </Stack>
        </Stack>
        <Box
          py={{ base: "0", sm: "8" }}
          px={{ base: "4", sm: "10" }}
          bg={{ base: "transparent", sm: "bg.surface" }}
          boxShadow={{ base: "none", sm: "md" }}
          borderRadius={{ base: "none", sm: "xl" }}
        >
          <Stack spacing="6">
            <Stack spacing="5">
              <FormControl isInvalid={!!passwordError}>
                {/* <FormLabel htmlFor="password">Password</FormLabel> */}
                <PasswordField
                  ref={passwordRef}
                  onChange={handlePasswordChange}
                />
                {passwordError && (
                  <FormErrorMessage>{passwordError}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={!!confirmPasswordError}>
                {/* <FormLabel htmlFor="confirmPassword">Confirm Password</FormLabel> */}
                <ConfirmPasswordField
                  ref={confirmPasswordRef}
                  onChange={validateConfirmPassword}
                />
                {confirmPasswordError && (
                  <FormErrorMessage>{confirmPasswordError}</FormErrorMessage>
                )}
              </FormControl>
            </Stack>

            <Stack spacing="6">
              <Button
                onClick={handleSubmit}
                disabled={
                  !passwordRef.current?.value ||
                  !!passwordError ||
                  !!confirmPasswordError
                }
                variant="primary"
              >
                Set up
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
};
