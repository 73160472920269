import MasterLayout from "../../layout/MasterLayout"
import { useRoles } from "../../context/RolesContext"
import RolesComponent from "../../components/Roles/RolesComponent"

const Sessions = () => {
    const { roleAccess, role, module, feature } = useRoles()

    return (
        <MasterLayout>
            {/* <Table accounts={accounts} /> */}
            <RolesComponent roleAccess={roleAccess} role={role} module={module} feature={feature} />
        </ MasterLayout>
    )
}

export default Sessions

