import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  StackDivider,
  Text,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { useState } from 'react';
import { useAuth } from '../../context/AuthContext';

export const ChangePasswordComponent = () => {
  const { changePassword } = useAuth();
  const toast = useToast();

  const [formValues, setFormValues] = useState({
    old_password: '',
    password: '',
    password_confirmation: '',
  });
  const toastId = 'password-toast';

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [oldPasswordError, setOldPasswordError] = useState<string | null>(null);
  const [newPasswordError, setNewPasswordError] = useState<string | null>(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState<string | null>(null);

  const validatePassword = (password: string) => {
    if (!password) {
      return 'Password is required'
    } else if (password.length < 8) {
      return 'Password is too short - should be 8 characters minimum'
    } else if (!/[A-Z]/.test(password)) {
      return 'Password must contain at least one capital letter'
    } else if (!/[a-z]/.test(password)) {
      return 'Password must contain at least one lowercase letter';
    }  else if (!/[0-9]/.test(password)) {
      return 'Password must contain at least one number';
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      return 'Password must contain at least one special character';
    } else {
      return null
    }
  };

  const handleOldPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFormValues({ ...formValues, old_password: value });
    setOldPasswordError(value ? null : 'Old password is required');
  };

  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFormValues({ ...formValues, password: value });
    setNewPasswordError(validatePassword(value));
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFormValues({ ...formValues, password_confirmation: value });
    setConfirmPasswordError(
      value !== formValues.password ? 'Passwords do not match' : null
    );
  };

  const handleSubmit = async () => {
    const oldPasswordValidationError = formValues.old_password ? null : 'Old password is required';
    const newPasswordValidationError = validatePassword(formValues.password);
    const confirmPasswordValidationError =
      formValues.password_confirmation !== formValues.password
        ? 'Passwords do not match'
        : null;

    setOldPasswordError(oldPasswordValidationError);
    setNewPasswordError(newPasswordValidationError);
    setConfirmPasswordError(confirmPasswordValidationError);

    if (!oldPasswordValidationError && !newPasswordValidationError && !confirmPasswordValidationError) {
      if (toast.isActive(toastId)) {
        toast.close(toastId);
      }
      if(formValues.old_password===formValues.password){
        toast({
          id: toastId,
          title: 'New password should not be old password',
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
        return
      }
      
      const res: any = await changePassword(formValues);
      if (res?.success) {
        toast({
          id: toastId,
                  title: 'Password updated successfully',
                  status: 'success',
                  position: 'top-right',
                  isClosable: true,
                });
      }

      if(res?.error){
        if (Array.isArray(res?.error)) {
                  res?.error.forEach((er:any) =>
                    toast({
                      id: toastId,
                      title: er,
                      status: 'error',
                      position: 'top-right',
                      isClosable: true,
                    })
                  );
                } else {
                  toast({
                    id: toastId,
                    title: res?.error,
                    status: 'error',
                    position: 'top-right',
                    isClosable: true,
                  });
                }
      }
      // console.info(res?.error,'check',res?.success)
    }
  };

  return (
    <Container maxW="container.xl" py={{ base: '4', md: '8' }}>
      <Stack spacing="5">
        <Stack spacing="4" direction={{ base: 'column', sm: 'row' }} justify="space-between">
          <Box>
            <Text textStyle="lg" fontWeight="medium">
              Security
            </Text>
          </Box>
          <Button onClick={handleSubmit} variant="primary" alignSelf="start">
            Update Password
          </Button>
        </Stack>
        <Divider />
        <Stack spacing="5" divider={<StackDivider />}>
          {/* Old Password Input */}
          <FormControl isInvalid={!!oldPasswordError}>
            <FormLabel>Old Password</FormLabel>
            <InputGroup>
              <Input
                type={showOldPassword ? 'text' : 'password'}
                onChange={handleOldPasswordChange}
                value={formValues.old_password}
              />
              <InputRightElement>
                <IconButton
                  variant="text"
                  aria-label={showOldPassword ? 'Hide password' : 'Show password'}
                  icon={showOldPassword ? <HiEyeOff /> : <HiEye />}
                  onClick={() => setShowOldPassword(!showOldPassword)}
                />
              </InputRightElement>
            </InputGroup>
            {oldPasswordError && <FormErrorMessage>{oldPasswordError}</FormErrorMessage>}
          </FormControl>

          {/* New Password Input */}
          <FormControl isInvalid={!!newPasswordError}>
            <FormLabel>New Password</FormLabel>
            <InputGroup>
              <Input
                type={showNewPassword ? 'text' : 'password'}
                onChange={handleNewPasswordChange}
                value={formValues.password}
              />
              <InputRightElement>
                <IconButton
                  variant="text"
                  aria-label={showNewPassword ? 'Hide password' : 'Show password'}
                  icon={showNewPassword ? <HiEyeOff /> : <HiEye />}
                  onClick={() => setShowNewPassword(!showNewPassword)}
                />
              </InputRightElement>
            </InputGroup>
            {newPasswordError && <FormErrorMessage>{newPasswordError}</FormErrorMessage>}
          </FormControl>

          {/* Confirm Password Input */}
          <FormControl isInvalid={!!confirmPasswordError}>
            <FormLabel>Confirm Password</FormLabel>
            <InputGroup>
              <Input
                type={showConfirmPassword ? 'text' : 'password'}
                onChange={handleConfirmPasswordChange}
                value={formValues.password_confirmation}
              />
              <InputRightElement>
                <IconButton
                  variant="text"
                  aria-label={showConfirmPassword ? 'Hide password' : 'Show password'}
                  icon={showConfirmPassword ? <HiEyeOff /> : <HiEye />}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              </InputRightElement>
            </InputGroup>
            {confirmPasswordError && <FormErrorMessage>{confirmPasswordError}</FormErrorMessage>}
          </FormControl>
        </Stack>
      </Stack>
    </Container>
  );
};
