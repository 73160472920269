import { AccountConfigComponent } from "../../components/Account/AccountConfigComponent"
import MasterLayout from "../../layout/MasterLayout"


const AccountConfig = () => {

    return (
        <MasterLayout>
            <AccountConfigComponent />
        </MasterLayout>
    )
}

export { AccountConfig }