import { createContext, useContext, useEffect, useMemo, useState } from "react";
import rolesService from "../services/roles.service";

const defaultState = {
  roleAccess: [],
  //   setRoles: (params: any) => {},
  role: [],
  module: [],
  feature: {},
  updateRole: (params: any) => {},
};

const RolesContext = createContext(defaultState);

export const RolesProvider = ({ children }: any) => {
  const [roleAccess, setRoleAccess] = useState<any>([]);
  const [role, setRole] = useState<any>([]);
  const [module, setModule] = useState<any>([]);
  const [feature, setFeature] = useState<any>([]);

  const getRoleAccess = async () => {
    const res = await rolesService.getRoleAccess();
    if (res?.success) {
      setRoleAccess(res.roles);
    }
  };

  const getRoleConfig = async () => {
    const res = await rolesService.getRoleConfig();
    if (res) {
      setRole(res.role);
      setModule(res.module);
      setFeature(res.feature);
    }
  };

  const updateRole = async (params: any) => {
    await rolesService.updateRole(params);
  };

  useEffect(() => {
    getRoleAccess();
    getRoleConfig();
  }, []);

  const value = useMemo(
    () => ({
      roleAccess,
      role,
      module,
      feature,
      updateRole,
    }),
    [roleAccess, role, module, feature]
  );
  return (
    <RolesContext.Provider value={value}>{children}</RolesContext.Provider>
  );
};

export const useRoles = () => {
  return useContext(RolesContext);
};
