import { Box, Button, Container, Divider, Popover, PopoverTrigger, PopoverContent, PopoverCloseButton, PopoverArrow, PopoverHeader, PopoverBody, Portal, Stack, Text, Avatar } from '@chakra-ui/react'
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../context/AuthContext';
import { Logo } from '../Logo';
import { useLocalStorage } from '../../hooks/useLocalStorage';

const Header = () => {
    const navigate = useNavigate()
    const { logout } = useAuth()
    const [user]=useLocalStorage('user',null)

    return (
        <Box as="section" bg="bg.surface" pt={{ base: '4', md: '8' }} 
            // pb={{ base: '4', md: '8' }}
            >
            <Container maxW="full" >
            <Stack spacing="5">
                <Stack spacing="4" direction={{ base: 'column', sm: 'row' }} justify="space-between">
                {/* <Box>
                <Logo/>
                    <Text textStyle="lg" fontWeight="medium">
                    Member overview
                    </Text>
                    
                 </Box> */} 
                <Stack direction="row" align="center" spacing="4">
              <Logo />
              <Text textStyle="lg" fontWeight="medium" color="rgb(74, 85, 104)">
                Admin Overview
              </Text>
              {/* <Text color="fg.muted" textStyle="sm">
                    All registered users in the overview
                    </Text> */}
            </Stack>
                <Popover>
                    <PopoverTrigger>
                        {/* <Button>Profile</Button> */}
                        <Avatar
                             name={`${user.first_name} ${user.last_name}`} 
                             src={user.avatar}
                             cursor="pointer"
                         />
                    </PopoverTrigger>
                    <Portal>
                        <PopoverContent>
                        <PopoverArrow />
                        {/* <PopoverHeader>Header</PopoverHeader> */}
                        {/* <PopoverCloseButton /> */}
                        {/* <PopoverBody> */}
                            <Button colorScheme='white' variant="ghost" onClick={() => navigate('/profile')} >Profile</Button>
                            <Divider />
                            <Button colorScheme='white' variant="ghost" onClick={() => navigate('/change_password')} >Change password</Button>
                            <Divider />
                            <Button colorScheme='white' variant="ghost" onClick={() => navigate('/roles')} >Roles</Button>
                            <Divider />
                            <Button colorScheme='white' variant="ghost" onClick={() => logout()} >Log out</Button>
                        {/* </PopoverBody> */}
                        {/* <PopoverFooter>This is the footer</PopoverFooter> */}
                        </PopoverContent>
                    </Portal>
                </Popover>
                {/* <Button variant="primary" alignSelf="start">
                    Profile
                </Button> */}
                </Stack>
                <Divider />
            </Stack>
            </Container>
        </Box>
    )
}

export { Header }