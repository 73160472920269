import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Stack,
  StackDivider,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { Dropzone } from '../Dropzone';
import { useEffect, useState } from 'react';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import PhoneInput, { Value } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useAuth } from '../../context/AuthContext';

export const ProfileComponent = () => {
  const [user, setUser] = useLocalStorage('user', null);
  const { updateUser } = useAuth();
  const toast = useToast();
  const toastId = 'password-toast';


  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    phone_number: '',
    avatar: user?.avatar || '',
  });

  useEffect(() => {
    if (user) {
      setFormData({
        first_name: user.first_name || '',
        last_name: user.last_name || '',
        phone_number: user.phone_number || '',
        avatar: user.avatar || '',
      });
    }
  }, [user]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handlePhoneChange = (value: Value) => {
    setFormData({
      ...formData,
      phone_number: value || '',
    });
  };

  const handleAvatarUpload = (newAvatarUrl: string) => {
    setFormData((prevData) => ({
      ...prevData,
      avatar: newAvatarUrl,
    }));
  };

  const handleSubmit = async () => {
    if (toast.isActive(toastId)) {
      toast.close(toastId);
    }
    const res: any = await updateUser(user._id ? user._id : user.id, formData);
    if (res.success) {
      toast({
        id: toastId,
                title: 'Updated successfully',
                status: 'success',
                position: 'top-right',
                isClosable: true,
              });
    }else{
      toast({
        id: toastId,
                title: 'Error on update',
                status: 'error',
                position: 'top-right',
                isClosable: true,
              });
    }
  };

  return (
    <Container maxW="container.xl" py={{ base: '4', md: '8' }}>
      <Stack spacing="5">
        <Stack spacing="4" direction={{ base: 'column', sm: 'row' }} justify="space-between">
          <Box>
            <Text textStyle="lg" fontWeight="medium">
              Your Profile
            </Text>
            <Text color="fg.muted" textStyle="sm">
              {`${user.first_name} ${user.last_name}`}
            </Text>
          </Box>
          <Button variant="primary" alignSelf="start" onClick={handleSubmit}>
            Save
          </Button>
        </Stack>
        <Divider />
        <Stack spacing="5" divider={<StackDivider />}>
          <FormControl id="first_name">
            <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '1.5', md: '8' }} justify="space-between">
              <FormLabel variant="inline">First Name</FormLabel>
              <Input maxW={{ md: '3xl' }} value={formData.first_name} onChange={handleInputChange} />
            </Stack>
          </FormControl>

          <FormControl id="last_name">
            <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '1.5', md: '8' }} justify="space-between">
              <FormLabel variant="inline">Last Name</FormLabel>
              <Input maxW={{ md: '3xl' }} value={formData.last_name} onChange={handleInputChange} />
            </Stack>
          </FormControl>

          <FormControl id="email">
            <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '1.5', md: '8' }} justify="space-between">
              <FormLabel variant="inline">Email</FormLabel>
              <Tooltip label="Email can't be updated" placement="top" bg="red.500">
                <Input type="email" maxW={{ md: '3xl' }} value={user.email} isReadOnly />
              </Tooltip>
            </Stack>
          </FormControl>

          <FormControl id="role">
            <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '1.5', md: '8' }} justify="space-between">
              <FormLabel variant="inline">Role</FormLabel>
              <Tooltip label="Role can't be updated" placement="bottom" bg="red.500">
                <Input type="text" maxW={{ md: '3xl' }} value={user.role} isReadOnly />
              </Tooltip>
            </Stack>
          </FormControl>

          <FormControl id="phone_number">
            <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '1.5', md: '8' }} justify="space-between">
              <FormLabel variant="inline">Phone Number</FormLabel>
              <Box maxW={{ md: '3xl' }} width="full">
                <PhoneInput
                  id="phone"
                  defaultCountry="IN"
                  value={formData.phone_number}
                  onChange={handlePhoneChange}
                  className="signUpPhone"
                  style={{ width: '100%' }}
                />
              </Box>
            </Stack>
          </FormControl>

          <FormControl id="picture">
            <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '1.5', md: '8' }} justify="space-between">
              <FormLabel variant="inline">Photo</FormLabel>
              <Stack spacing={{ base: '3', md: '5' }} direction={{ base: 'column', sm: 'row' }} width="full" maxW={{ md: '3xl' }}>
                <Avatar size="lg" name={`${formData.first_name} ${formData.last_name}`} src={formData.avatar} />
                <Dropzone onAvatarUpload={handleAvatarUpload} />
              </Stack>
            </Stack>
          </FormControl>
          
        </Stack>
      </Stack>
    </Container>
  );
};
