import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Stack,
  StackDivider,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import PhoneInput, { Value } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useAccount } from "../../context/AccountContext";

export const CreateAccountComponent = () => {
  const { createAccount }: any = useAccount();
  const toast = useToast();

  // Initial form values
  const initialFormValues = {
    first_name: "",
    last_name: "",
    company_name: "",
    companyUrl: "",
    accountPlan: "",
    businessName: "",
    businessDescription: "",
    corporationType: "",
    location: "",
    teamSize: "",
    email: "",
    password: "",
    phone_number: "",
  };

  const [accountForm, setAccountForm] = useState<any>(initialFormValues);

  const validate = (data: any) => {
    const newErrors: any = {};
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!.%*?&]{8,}$/;
    const phoneRegex = /^\+?[1-9]\d{1,3}[ -]?\d{4,14}$/;
    if (!data.first_name) {
      newErrors["first_name"] = "First Name Required";
    }
    if (!data.last_name) {
      newErrors["last_name"] = "Last Name Required";
    }
    if (!(data.email && emailRegex.test(data.email))) {
      newErrors["email"] = "Please provide valid Email";
    }
    if (!(data.password && passwordRegex.test(data.password))) {
      newErrors["Password"] =
        "Password Should be minium 8 character 1 special character 1 Upper case 1 Lower Case";
    }

    if (!(data.phone_number && phoneRegex.test(data.phone_number))) {
      newErrors["phone_number"] =
        "Please Provide Phone Number with Country Code";
    }

    if (!data.company_name) {
      newErrors["company_name"] = "Company Name required";
    }

    if (!data.accountPlan) {
      newErrors["account_plan"] = "Please select plan";
    }
    return newErrors;
  };

  const handlePhoneChange = (value: Value) => {
    setAccountForm((prevForm: any) => ({
      ...prevForm,
      phone_number: value || "",
    }));
  };

  const handleSubmit = async () => {
    const params: any = {
      first_name: accountForm.first_name,
      last_name: accountForm.last_name,
      email: accountForm.email,
      password: accountForm.password,
      role: "OWNER",
      phone_number: accountForm.phone_number,
      company_name: accountForm.company_name,
    };

    const newErrors = validate(accountForm);
    if (Object.keys(newErrors).length !== 0) {
      const error: any = Object.values(newErrors);
      toast({
        title: "Validation Error",
        description: error[0],
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } else {
      params["meta"] = accountForm;
      params["accept_terms_of_service"] = true;
      const res = await createAccount(params);

      if (res.success) {
        setAccountForm(initialFormValues);
      }
    }
  };

  return (
    <Container maxW="container.xl" py={{ base: "4", md: "8" }}>
      <Stack spacing="5">
        <Stack
          spacing="4"
          direction={{ base: "column", sm: "row" }}
          justify="space-between"
        >
          <Flex>
            <Text textStyle="lg" fontWeight="medium">
              Create Account
            </Text>
          </Flex>
          <Flex>
            <Button
              onClick={() => handleSubmit()}
              variant="primary"
              alignSelf="start"
            >
              Create
            </Button>
          </Flex>
        </Stack>
        <Divider />
        <Stack spacing="5" divider={<StackDivider />}>
          <FormControl id="name">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <FormLabel variant="inline">First Name</FormLabel>
              <Input
                onChange={(e: any) =>
                  setAccountForm({ ...accountForm, first_name: e.target.value })
                }
                maxW={{ md: "3xl" }}
                value={accountForm?.first_name}
              />
            </Stack>
          </FormControl>
          <FormControl id="name">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <FormLabel variant="inline">Last Name</FormLabel>
              <Input
                onChange={(e: any) =>
                  setAccountForm({ ...accountForm, last_name: e.target.value })
                }
                maxW={{ md: "3xl" }}
                value={accountForm?.last_name}
              />
            </Stack>
          </FormControl>
          <FormControl id="name">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <FormLabel variant="inline">Email</FormLabel>
              <Input
                onChange={(e: any) =>
                  setAccountForm({ ...accountForm, email: e.target.value })
                }
                maxW={{ md: "3xl" }}
                value={accountForm?.email}
              />
            </Stack>
          </FormControl>
          <FormControl id="name">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <FormLabel variant="inline">Password</FormLabel>
              <Input
                type="password"
                onChange={(e: any) =>
                  setAccountForm({ ...accountForm, password: e.target.value })
                }
                maxW={{ md: "3xl" }}
                value={accountForm?.password}
              />
            </Stack>
          </FormControl>
          <FormControl id="name">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <FormLabel variant="inline">Phone number</FormLabel>
              {/* <Input onChange={(e: any) => setAccountForm({...accountForm, phone_number: e.target.value})} maxW={{ md: '3xl' }} value={accountForm?.phone_number} /> */}
              <Box maxW={{ md: "3xl" }} width="100%">
                <PhoneInput
                  defaultCountry="IN"
                  value={accountForm.phone_number}
                  onChange={handlePhoneChange}
                  className="signUpPhone"
                  style={{ width: "100%" }}
                  // maxW={{ md: '3xl' }}
                />
              </Box>
            </Stack>
          </FormControl>
          <FormControl id="name">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <FormLabel variant="inline">Company Name</FormLabel>
              <Input
                onChange={(e: any) =>
                  setAccountForm({
                    ...accountForm,
                    company_name: e.target.value,
                  })
                }
                maxW={{ md: "3xl" }}
                value={accountForm?.company_name}
              />
            </Stack>
          </FormControl>
          <FormControl id="website">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <FormLabel variant="inline">Company Url</FormLabel>
              <InputGroup maxW={{ md: "3xl" }}>
                <InputLeftAddon>https://</InputLeftAddon>
                <Input
                  onChange={(e: any) =>
                    setAccountForm({
                      ...accountForm,
                      companyUrl: e.target.value,
                    })
                  }
                  value={accountForm?.companyUrl}
                />
              </InputGroup>
            </Stack>
          </FormControl>
          <FormControl id="name">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <FormLabel variant="inline">Account Plan</FormLabel>
              <Select
                onChange={(e: any) =>
                  setAccountForm({
                    ...accountForm,
                    accountPlan: e.target.value,
                  })
                }
                value={accountForm?.accountPlan}
                maxW={{ md: "3xl" }}
                placeholder="Select Account Plan"
              >
                <option value="FREE">Free</option>
                <option value="PRO">Pro</option>
                <option value="ENTERPRISE">Enterprise</option>
              </Select>
              {/* <Input onChange={(e: any) => setAccountForm({...accountForm, accountPlan: e.target.value})} maxW={{ md: '3xl' }} value={accountForm?.accountPlan} /> */}
            </Stack>
          </FormControl>
          <FormControl id="name">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <FormLabel variant="inline">Business Name</FormLabel>
              <Input
                onChange={(e: any) =>
                  setAccountForm({
                    ...accountForm,
                    businessName: e.target.value,
                  })
                }
                maxW={{ md: "3xl" }}
                value={accountForm?.businessName}
              />
            </Stack>
          </FormControl>
          <FormControl id="name">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <FormLabel variant="inline">Corporation Type</FormLabel>
              <Input
                onChange={(e: any) =>
                  setAccountForm({
                    ...accountForm,
                    corporationType: e.target.value,
                  })
                }
                maxW={{ md: "3xl" }}
                value={accountForm?.corporationType}
              />
            </Stack>
          </FormControl>
          <FormControl id="name">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <FormLabel variant="inline">Location</FormLabel>
              <Input
                onChange={(e: any) =>
                  setAccountForm({ ...accountForm, location: e.target.value })
                }
                maxW={{ md: "3xl" }}
                value={accountForm?.location}
              />
            </Stack>
          </FormControl>
          <FormControl id="name">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <FormLabel variant="inline">Team Size</FormLabel>
              <Select
                onChange={(e: any) =>
                  setAccountForm({ ...accountForm, teamSize: e.target.value })
                }
                value={accountForm?.teamSize}
                maxW={{ md: "3xl" }}
                placeholder="Select Team Size"
              >
                <option value="1-1"> 1 - 1</option>
                <option value="2-10"> 2 - 10</option>
                <option value="10-50">10 - 50</option>
                <option value="50+"> 50+</option>
              </Select>
            </Stack>
          </FormControl>
          <FormControl id="bio">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <Box>
                <FormLabel variant="inline">Business Description</FormLabel>
                <FormHelperText mt="0" color="fg.muted">
                  Write a short introduction about you
                </FormHelperText>
              </Box>
              <Textarea
                onChange={(e: any) =>
                  setAccountForm({
                    ...accountForm,
                    businessDescription: e.target.value,
                  })
                }
                value={accountForm?.businessDescription}
                maxW={{ md: "3xl" }}
                rows={5}
                resize="none"
              />
            </Stack>
          </FormControl>

          {/* <Flex direction="row-reverse">
            <Button variant="primary">Save</Button>
          </Flex> */}
        </Stack>
      </Stack>
    </Container>
    // {/* </Formik> */}
  );
};
