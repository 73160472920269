import { ChangePasswordComponent } from "../../components/ChangePassword/ChangePassowrdComponent";
import MasterLayout from "../../layout/MasterLayout";

const ChangePassword = () => {
  return (
    <MasterLayout>
      <ChangePasswordComponent />
    </MasterLayout>
  );
};

export { ChangePassword };
