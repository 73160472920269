import { useToast } from "@chakra-ui/react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import accountService from "../services/account.service";

const defaultState = {
  accountId: "",
  users: [],
  getAccountById: (id: string) => {},
  getAccountUsers: (id: string) => {},
  regenerateApiKey: (id: string) => {},
  deleteUserById: (id: string, userId: string) => {},
  usersMeta: {
    pageLimit: 0,
    currentPage: 0,
    total_count: 0,
  },
  changeUserAccount: (_meta: any, id: string) => {},
  inviteUser: (id: string, params: any) => {},
  updateUserByAccountId: (accountId: string, userId: string, params: any) => {},
};

const AccountUsersContext = createContext(defaultState);

export const AccountUsersProvider = ({ children }: any) => {
  const [users, setUsers] = useState([]);
  const [usersMeta, setUsersMeta] = useState<any>([]);
  const { id } = useParams();
  const toast = useToast();

  const changeUserAccount = (_meta: any, id: string) => {
    let meta: any = {
      page: _meta.currentPage || _meta.page,
      limit: _meta.pageLimit || 10,
      sort: "-createdAt",
    };

    if (_meta.sort) {
      meta.sort = _meta.sort;
    }
    getAccountUsers(id, meta);
  };

  const inviteUser = async (id: string, params: any) => {
    const res = await accountService.inviteUser(id, params);
    if (res?.success) {
      toast({
        title: `${params.first_name} ${params.last_name} Invited`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
    return res;
  };

  const getAccountById = async (id: string) => {
    const res = await accountService.getAccountById(id);
    if (res?.success) {
      return res.account;
    }
    return null;
  };

  const regenerateApiKey = async (id: string) => {
    const res = await accountService.regenerateApiKey(id);
    if (res?.success) {
      return res.account;
    }
    return null;
  };

  const getAccountUsers = async (id: string, meta?: any) => {
    const res = await accountService.getAccountUsers(id, meta);
    if (res?.success) {
      setUsersMeta(res.meta);
      setUsers(res.users);
    }
  };

  const deleteUserById = async (id: string, userId: string) => {
    const res = await accountService.deleteUserByAccountId(id, userId);

    return res;
  };

  const updateUserByAccountId = async (
    accountId: string,
    userId: string,
    params: any
  ) => {
    const res = await accountService.updateUserByAccountId(
      accountId,
      userId,
      params
    );
    return res;
  };

  useEffect(() => {
    getAccountUsers(id!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({
      accountId: id!,
      users,
      getAccountById,
      getAccountUsers,
      regenerateApiKey,
      deleteUserById,
      usersMeta,
      changeUserAccount,
      inviteUser,
      updateUserByAccountId,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [users]
  );
  return (
    <AccountUsersContext.Provider value={value}>
      {children}
    </AccountUsersContext.Provider>
  );
};

export const useAccountUsers = () => {
  return useContext(AccountUsersContext);
};
