import { Table } from "../../components/Table/table";
import { useAllUsers } from "../../context/AllUsersContext";
import MasterLayout from "../../layout/MasterLayout";

const AllUsers = () => {
  const { allUsers } = useAllUsers();

  return (
    <MasterLayout>
      <Table allUsers={allUsers} />
    </MasterLayout>
  );
};

export default AllUsers;
