import {
  Button,
  Center,
  CenterProps,
  HStack,
  Icon,
  Square,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { FiUploadCloud } from 'react-icons/fi';
import { useState } from 'react';
import { useAuth } from '../context/AuthContext';

export const Dropzone = ({ onAvatarUpload }: { onAvatarUpload: (url: string) => void }) => {
  const [file, setFile] = useState<File | null>(null);
  const { uploadAvatar } = useAuth();
  const toast = useToast();
  const toastId = 'password-toast';

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);

      setFile(e.target.files[0]);
      const res: any = await uploadAvatar(formData);
      if (res.success) {
        onAvatarUpload(res.url);
      }else{
        toast({
          id: toastId,
                  title: 'Error on upload',
                  status: 'error',
                  position: 'top-right',
                  isClosable: true,
                });
      }
    }
  };

  return (
    <Center
      borderWidth="1px"
      borderRadius="lg"
      px="6"
      py="4"
      bg={useColorModeValue('white', 'gray.800')}
      className=' for-input'
      width={"full"}
    >
      <input type="file" accept="image/png, image/jpeg, image/gif" onChange={handleFileSelect} className="for-input-file"/>
      <VStack spacing="3">
        <Square size="10" bg="bg.subtle" borderRadius="lg">
          <Icon as={FiUploadCloud} boxSize="5" color="fg.muted" />
        </Square>
        <VStack spacing="1">
          <HStack spacing="1" whiteSpace="nowrap">
            <Button variant="text" colorScheme="blue" size="sm">
              Click to upload
            </Button>
          </HStack>
          <Text textStyle="xs" color="fg.muted">
            PNG, JPG or JPEG up to 2MB
          </Text>
          {file ? (
            <Text textStyle="sm" color="fg.muted">
              Selected file: {file.name}
            </Text>
          ) : (
            <Text textStyle="sm" color="fg.muted">
              No file selected
            </Text>
          )}
        </VStack>
      </VStack>
    </Center>
  );
};
